.workhour-config-list .griddle-filter {
    display: none;
}

@media (max-width: 992px) {
    .workhour-config-list {
        margin-top: 10px;
    }
}

.workhour-config-list-row {
    margin: 10px;
}

.workhour-config-list-row .enquiry-container {
    margin-top: 10px;
    margin-bottom: 20px;
}

.workhour-config-list-row .tab-content>.active {
    padding: 20px;
}

.workhour-config-list .griddle-pagination {
    margin-top: 10px;
}

.workinghour-btn {
    background-color: #0772b8;
    margin-right: 5px;
    font-size: 15px;
    margin-bottom: 3px;
}

.workinghour-btn:hover {
    background-color: #2a59b8;
}

.workinghour-btn.disabled:hover {
    background-color: #6c757d;
}

.workinghourexcep-btn {
    background-color: #0772b8;
    font-size: 15px;
    margin-bottom: 3px;
    float: right
}

.workinghourexcep-btn:hover {
    background-color: #2a59b8;
}

.wh-buttons-col {
    display: flex;
    height: fit-content;
}

.wh-cat-col {
    margin-bottom: 40px;
    padding: 0px;
    display: contents
}

.wh-cat-row {
    justify-content: center;
    margin-bottom: 10px;
}

.wh-cat-col-list {
    width: 100%
}