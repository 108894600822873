/*************** Styling: Footer ***************/
.footer {
    position: fixed;
    width: 220px;
    bottom: 0;
    padding-bottom: 10px;
    padding-left: 20px;
    font-size: .7em;
}

/* .footer-copyright {
    padding-top: 4px;
    margin-top: 1.5px;
    margin-bottom: -15px;
    background-color: white;
} */