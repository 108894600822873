/*************** Styling: RichTextEditor ***************/
.SMS-RTE-demo-editor {
    height: 150px !important;
}

.SMS-RTE-demo-wrapper {
    height: 150px !important;
    margin-bottom: 0px !important;
}

.Email-TemplateSubject-demo-editor {
    height: 150px !important;
}

.Email-TemplateSubject-demo-wrapper {
    height: 150px !important;
    margin-bottom: 0px !important;
}

.Email-TemplateSubject-demo-editor .rdw-suggestion-option,  .Email-TemplateBody-demo-editor .rdw-suggestion-option{
    padding: 3px 5px;
    font-size: 0.75rem;
}
.Email-TemplateSubject-demo-editor .rdw-suggestion-dropdown, .Email-TemplateBody-demo-editor .rdw-suggestion-option{
    max-height: 76px;
}

/*************** Styling: Category drop down list ***************/
div[name="Category"] .form-group{
    margin-bottom: 0px;
}
