.theme-new .title-text.col {
    color: #005eb8;
    font-size: 30px;
    margin-bottom: 5px;
    line-height: 100%;
}

.theme-new.body-title-text {
    background: #f7f9fc;
    border: none;
    font-weight: 500;
    color: #666666;
    font-size: 16px;
}

.theme-new.smart-content .tab-container {
    border: none;
    padding-left: 0;
    padding-right: 0;
}

.theme-new.smart-content .tab-nav-tabs .tab-nav-item .tab-header.tab-nav-link.nav-link {
    border: none;
    padding: 10px 35px;
    color: #666666;
    border-bottom: 3px solid transparent;
}

.theme-new.smart-content .tab-nav-tabs .tab-nav-item {
    margin: 0 10px 0 0;
}

.theme-new.smart-content .tab-nav-tabs .tab-nav-item .tab-header.tab-nav-link.nav-link.active, .smart-content .tab-nav-tabs .tab-nav-item .tab-header.tab-nav-link.nav-link:hover {
    border-bottom: 3px solid #005eb8;
    color: #005eb8;
}

.theme-new.smart-content .tab-container .container {
    max-width: 100%;
    padding: 0;
}

.theme-new.smart-content .tab-container .container .body-content.row {
    margin: 0;
}

.theme-new.smart-content .tab-container .container .body-content.row .grid-container {
    padding: 0;
}

.theme-new.smart-content .tab-container .container .body-content.row .col {
    padding: 0;
}

.theme-new.smart-content .tab-container .griddle-table th {
    background: #005eb8;
    color: #fff;
    text-transform: uppercase;
    border: none;
}

.theme-new.smart-content .tab-container .griddle-table td.griddle-cell .form-group {
    margin-bottom: 0;
}

.theme-new.smart-content .tab-container .griddle-table td.griddle-cell .form-group label.label-default.col-lg-3.col-form-label {
    display: none;
}

.theme-new.smart-content .tab-container table.griddle-table {
    border-left: 0;
    border-right: 0;
}

.theme-new .action-button-group .btn.btn-forward {
    background: none;
    border: 1px solid #ccc!important;
}


.theme-new .action-button-group .btn.btn-forward svg {
    color: #005eb8;
    width: 24px;
    height: 20px;
}

html .modal .modal-dialog.theme-new .modal-header {
    background: #0063a7;
    color: #fff;
    padding: 20px 50px;
}

html .modal .modal-dialog.theme-new .modal-header  h5.modal-title {
    color: #fff;
    font-weight: 300;
}

html .modal .modal-dialog.theme-new .col h4 {
    font-size: 18px;
    color: #005eb8;
}

html .modal .modal-dialog.theme-new .body-content {
    margin: 30px 0 0 0;
}

html .modal .modal-dialog.theme-new .modal-footer {
    background: #e3e8ea;
    padding: 20px 40px;
}

html .modal .modal-dialog.theme-new .modal-footer button.btn.btn-sm {
    padding: 10px 25px;
}

.theme-new .tab-heading {
    color: #666;
    font-size: 22px;
    margin: 20px 0;
}

.theme-new .note {
    background-color: #d9654e;
    color: #fff;
    padding: 15px;
    font-size: 16px;
    padding-left: 60px;
    background-image: url(../../../assets/images/bulb.png);
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: left 20px center;
}