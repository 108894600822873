/*************** Styling: Modal - General ***************/


.modal-title {
    font-size: 14px;
    font-weight: 600;
}

.modal-body {
    font-size: 13px;
}

.modal-content{
    width: auto;
}

.rfaDetailsHeaderFont .modal-title{
    font-size: 20px;
    font-weight: 600;
    font-weight: bold;
}

.rfaDetailsModal {
    min-width: 650px;
}