
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body{
	 font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
}

.btn-outline-primary {
    background: #fff;
    color: #005eb8!important;
    border: 1px solid #005eb8!important;
}


.btn-group .btn-outline-primary, .input-group .btn-outline-success {
    background: none;
    color: #5285bf;
    border: 1px solid #ccc;
    border-radius: 5px!important;
    margin-left: 5px!important;
    background: #fff;
    padding: 0.35rem 0.65rem;
}

.btn-outline-primary:hover, .input-group .btn-outline-success:hover {
    background: #045284 !important;
    color: #fff !important;
    border: 1px solid transparent !important;
}

.container-fluid > .row.row-cols-auto .col .btn-outline-primary {
    min-height: 46px;
    font-weight: 600;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background: #005eb8;
    color: #fff!important;
}

.middle-title.form-label {
    font-weight: 700;
}

.form-check-input[type=checkbox] {
    border-radius: 0.25em;
    border: 1px solid #ccc;
    width: 22px;
    height: 22px;
    top: 5px;
}

.form-check-input[type=checkbox]:checked {
    background-color: #045284;
    border: 1px solid transparent;
}

hr {
    border: none;
}

hr ~ [role=button] {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    border-top: 2px solid #3e6ac7;
	padding-bottom: 0.5rem;
}

hr ~ [role=button] .mb-3.row.row-cols-auto {
    border-bottom: 1px solid #ccc;
    margin: 0 -1.5rem;
    padding-bottom: 1rem;
    padding-left: 32px;
    align-items: center;
}

hr ~ [role=button] > .row > .col-auto {
    position: absolute;
} 


hr ~ [role=button] .btn-group > div {
    margin-right: 5px;
    margin-bottom: 10px;
    min-width: 150px;
}

.row-cols-auto > div .btn-group {
    width: 100%;
    padding: 0.75rem;
    flex-wrap: wrap;
    /*padding-right: 240px;*/
    align-items: center;
    border-radius: 5px;
    background: #edf6ff;
    border-color: lightblue;
    border-style: double;
}

.row-cols-auto > div .btn-group .form-control, .row-cols-auto > div .btn-group .form-select {
    border: 1px solid #ccc;
    border-radius: 4px !important;
    height: 40px;
}



.row-cols-auto > div .btn-group .input-group span.input-group-text {
    border-radius: 0 4px 4px 0!important;
    border: none;
    background: none;
    padding: 0;
    position: absolute;
    left: 0;
    top: 5px;
}


hr ~ [role=button] .btn-group .col-auto:nth-last-child(3) {
    flex: auto;
    max-width: 230px;
    margin-left: auto;
    min-width: 230px;
}

h1 {
    padding: 1.5rem 2rem;
    margin: 0 -2rem!important;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem!important;
    font-size: 1.76rem!important;
    font-weight: 300!important;
    color: rgb(51 51 51)!important;
    margin-top: 0!important;
    text-transform: capitalize;
}
form > .row.row-cols-auto .col {
    padding-left: 0;
}

.container-fluid > .row.row-cols-auto .col {
    padding-right: 0;
}

hr ~ [role=button] hr {
    margin: 0;
    border: none;
    padding: 0;
}

#DndLiveRegion-0 ~ .mb-3.row .col-auto {
    padding-right: 0;
}



.modal-dialog.modal-dialog-centered {
    min-width: 700px;
}

.modal-dialog.modal-dialog-centered .modal-header {
    color: #005eb8;
    padding: 2rem;
}

.modal-dialog.modal-dialog-centered .modal-body {
    padding: 2rem;
}

.modal-dialog.modal-dialog-centered  .modal-footer {
    justify-content: center;
    padding: 2rem;
}

.modal-dialog.modal-dialog-centered .modal-footer .btn {
    margin: 0 7px;
}

.modal-dialog.modal-dialog-centered .modal-footer .btn-outline-danger {
    background: #dc3545;
    color: #fff;
}

.modal-dialog.modal-dialog-centered .modal-footer .btn-outline-secondary {
    background: #6c757d;
    color: #fff;
}

.modal-dialog.modal-dialog-centered .modal-footer .btn-outline-success {background: #198754;color: #fff;}

.modal-dialog.modal-dialog-centered .modal-body .mb-1.col-5 {
    max-width: 145px;
}

.modal-dialog.modal-dialog-centered .modal-content {
    border: none;
}

.input-group span.custom-border.input-group-text {
    display: none;
}

hr ~ [role=button] .mb-2.row.row-cols-auto > div {
    width: 100%;
}

hr ~ [role=button] .btn-group > div:nth-child(1), hr ~ [role=button] .btn-group > div:nth-child(2), hr ~ [role=button] .btn-group > div:nth-child(3), hr ~ [role=button] .btn-group > div:nth-child(4) {
    width: calc(25% - 5px);
    flex: auto;
    min-width: calc(25% - 5px);
    max-width: calc(25% - 5px);
}

/*hr ~ [role=button] .btn-group .col-auto:last-child {
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    background: #fff;
    margin: 0;
    border: 4px solid #efefef;
    border-radius: 0 5px 5px 0;
    border-left: 0;
}
*/
.row-cols-auto > div .btn-group .input-group .form-control[disabled] {
    background: none;
    border: none;
    text-align: left;
    margin-left: 0;
    font-size: 0.9rem;
}

.row-cols-auto > div .btn-group .input-group span.custom-border.input-group-text {
    display: inline-flex;
    position: static;
    border-width: 1px;
    background: #f8f9fa;
    border: 1px solid #ccc;
    padding: .375rem .75rem;
    border-radius: 4px 0 0 4px!important;
}

hr ~ [role=button] .btn-group > div.col {
    width: auto;
    min-width: 0;
    max-width: 200px;
}
hr ~ [role=button] .btn-group > div.col input.custom-border.form-control {
    border-radius: 0 4px 4px 0!important;
}

.container-fluid > .row.row-cols-auto .col:last-child .btn-outline-primary {
    background: #fff;
    color: #005eb8!important;
}



.container-fluid > .row.row-cols-auto .col:last-child .btn-outline-primary:hover {
    color: #fff!important;
    background: #045284!important;
}
#DndLiveRegion-0 ~ .mb-3.row .col-auto:nth-child(2) .btn {
    background: #005eb8;
    color: #fff!important;
}

select.form-control.is-invalid, select.was-validated .form-control:invalid, select.form-control.is-valid, select.was-validated .form-control:valid {
    background-position: right 20px center;
}

.row-cols-auto > div .btn-group .col-1:nth-child(5) {
    margin-left: 1.25rem;
}

.container-fluid > .row.row-cols-auto .col {
    width: auto;
    max-width: fit-content;
    margin-bottom: 1rem;
}

hr ~ [role=button] .mb-3.row.row-cols-auto label.middle-title {
    margin: 0;
}

hr ~ [role=button] .mb-3.row.row-cols-auto .btn-group > div {
    margin-bottom: 0;
}

hr ~ [role=button] > .row > .col-auto {
    margin-top: 8px;
}

hr ~ [role=button] {
    padding-top: 1rem;
}

.row-cols-auto > div .btn-group {
    padding-bottom: 0;
    padding-top: 1rem;
    padding-left: 0;
    padding-right: 0;
}

hr ~ [role=button] .btn-group .col-auto:nth-last-child(3) {
    max-width: 205px;
    min-width: 205px;
}
form[class] > .row.row-cols-auto {
    align-items: center;
}

form[class] > .row.row-cols-auto label.middle-title {
    margin: 0;
}

.middle-title {
    font-weight: 600!important;
    color: #000!important;
}

[role=button] div[role="button"] {
    margin-left: 22px;
}

.break {
    flex-basis: 100%;
    height: 0;
}