.remove-faq-quesion-icon{
    margin-top: 33px !important;
    margin-left: -11px !important;
}

.tree-content-loader {
    display: flex !important;
    justify-content: center;
    margin-top: 30px !important;
}

    .tree-content-loader .ant-spin-text {
        margin-left: 10px;
    }


.more-qn-btn {
    margin-bottom: 30px !important;
}

.tree-toggle {
    padding-bottom: 0px;
    top: -10px;
}

.tree-card-header > .form-group {
    width: 100%;
}

    .tree-card-header > .form-group .form-control {
        height: 30px;
        font-size: 15px;
    }

.breadcrumb-padding{
    padding-bottom: 12px !important;
}

.ant-menu-item {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.node-name {
    display: inline-flex;
}

.tree-menu-item {
    background: white;
    z-index: 5;
    font-size: .8rem;
}

    .tree-menu-item:hover {
        background-color: #E2EFFF;
    }

    .actions-custom .fa-ellipsis-h:hover {
        color: #5385BF;
    }

.node .actions-custom {
    position: relative;
    display: none;
    float: right;
    background-color: transparent;
    right: 0;
    top: 1px;
    margin-left: 10px;
}

.tree ul li .node:hover .actions-custom {
    display: block;
}

.dropdown-menu.show {
    top: 10px !important;
    left: 5px !important;
    font-size: .8rem;
}

.dropdown-menu {
    min-width: 140px;
}

.dropdown-item {
    padding: 5px 5px 5px 10px;
}