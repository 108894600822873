.language-management-rich-text.direction-rtl {
    text-align: right;
}
.key-card-action-button {
    float: right;
}

.card-container .key-card-header {
    background-color: white;
    color: black;
}

.card-body {
    padding-bottom: 7px;
}

html[dir=ltr] .card-translated-value {
    border-left: 1px solid rgba(0, 0, 0, .225);
}
html[dir=rtl] .card-translated-value {
    border-right: 1px solid rgba(0, 0, 0, .225);
}

.key-name-space {
    color: rgba(0, 0, 0, 0.65);
    text-align: start;
}

.key-row {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 4px;
    margin-bottom: 10px;
}

.key-card-body {
    padding-top: 0.25rem;
}

html[dir=ltr] .copy-icon {
    padding: 0px 0px 0px 10px;
}
html[dir=rtl] .copy-icon {
    padding: 0px 10px 0px 0px;
}

.language-container {
    box-shadow: 0px 0px 3px 0px rgb(228, 228, 228);
    padding: 20px;
}

.language-header-content {
    margin: 0px 20px 0px 20px !important;
    justify-content: center;
}

.direction-rtl {
    direction: rtl
}

.direction-ltr {
    direction: ltr
}

.remove-padding {
    padding: 0;
}

.lang-settings-header {
    margin-bottom: 20px;
}
.remove-faq-question-icon {
    margin-top: 33px !important;
    margin-left: -11px !important;
    font-size: 20px;
    cursor: pointer;
}

.remove-language-icon {
    margin-top: 7px;
    font-size: 20px;
    cursor: pointer;
}

.edit-error-msg {
    margin-top: -10px;
}

.rich-text-icon {
    padding: 0;
    position: absolute;
    bottom: 5px;
    right: 0;
}

.table-language-list {
    margin-bottom: 10px;
}

.table-language-list .badge {
    margin-left: 10px;
}

.highlight-background {
    background-color: #f9f57a;
}

.add-padding-top {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-left-right {
    padding-left: 15px;
    padding-right: 15px;
}

.language-btn {
    background-color: #0772b8;
    margin-right: 5px;
    font-size: 15px;
}

.language-btn:hover {
    background-color: #2a59b8;
}

.language-btn.disabled:hover {
    background-color: #6c757d;
}
.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.badge-icon.text-toggle {
    cursor: pointer;
}

.text-toggle-col {
    padding-left: 0;
}