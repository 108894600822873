/** Enquiry CSS here **/

.enquiry-container{
    width:100%;
    overflow: visible;
    background-color: #fff;
    border: 0px !important;
}

.enquiry-card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: visible;
    background-color: #fff;
}

.enquiry-grid-container {
    padding: 0px !important;
    width: 100%;
    margin-top: 10px;
}

.panel-card > .start-card-body > .smart-content > .body-content {
    margin: 30px;
    margin-bottom: 0px;
}

.record-count{
    padding-top: 7px;
    font-weight: 600;
    text-align: start;
}

.col-gutter{
    padding-left: 10px;
    padding-right: 10px;
}

.full-width{
    max-width: 100%;
}
/* Chevron */
.chevron{
    text-align: end;
    padding-top: 7px;
    cursor: pointer;
}

/* Filter Display */
.enquiry-grid-container .griddle-filter{
    display: none !important;
}

/* Justify Center of Search Section */
.enquiry-card-body .form-group{
    margin-bottom: 23.5px;
    font-size: 0.85rem;
    justify-content: center;
}

.enquiry-card-body > .button-row {
    justify-content: center;
}

.licence-application-link {
    color: #0772b8;
    cursor: pointer;
}

.licence-application-link:hover {
    text-decoration: underline;
}

/* Width of Search Section */
.collapse-enquiry{
    width: 100%;
}

/* .enquiry-card-header{
    background-color: #3d6ac7;
    color: white;
    padding-top: 0.4em !important;
    padding-bottom: 0.4em !important;
    margin-top: 0 !important;
} */

.badge-success{
    background-color: #57984f;
}

.card-footer{
    padding-top: 0.2em !important;
    padding-bottom: 0.2em !important;
}

.card-icon-grp{
    text-align: center;
}

.card-button{
    width: 30px;
    height: 30px;
    padding-right: 15px;
}

.card-button-icon{
    padding-right: 10px;
    font-size: 25px;
}

.horizontal-line{
    border-bottom: 1px solid #e8e8e8;
    margin-left: 10px; 
    margin-right: 10px;
}

.divstyle{
    margin-left: 10px;
    margin-top: 11px;
    border: 0;

}

.title-click{
    color: red;
}

.eye-icon{
    padding-right: 16px;
    color: #888;
    font-size: 15px;
}

.trash-icon{
    padding-right: 16px;
    color: #888;
    font-size: 15px;
}

.overview-margin.form-group {	
    margin-bottom: 0px;	
}

@media only screen and (max-width: 992px){

    .enquiry-card-body{
        padding: 0px;
    }
    .enquiry-card-body .form-group {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .col-gutter{
        padding-left: 0px;
        padding-right: 0px;
    }

    .vertical.site-container > .smart-content > .body-content {
        margin-left: -15px;
        margin-right: -15px;
        overflow: visible;
    }

    .vertical .panel-card > .start-card-body > .smart-content > .body-content {
        margin: 0px -15px !important;
    }

    .pagination-group {
        float: none !important;
        margin: auto;
        width: fit-content;
    }

    .horizontal .enquiry-card-body > .button-row {
        -ms-flex-pack: center;
        justify-content: center;
        padding-top: 5px;
        padding-bottom: 15px;
    }

    .horizontal .enquiry-card-body .btn {
        text-transform: none;
        padding-left: 10px;
        padding-right: 10px;
        height: 40px;
        width: 92%;
        margin: 2px auto 2px;
    }

    .advanced-search{
        margin-bottom: 10px;
    }
}