/*************** Styling: Profile ***************/

.profile-button{
    border-color: #fff;
}

.profile {
    align-self: center
}

@media only screen and (max-width: 992px) {

    .profile-dropdown{
        margin-left: auto;
    }
    .profile-text{
        display: none;
    }
    .profile a.btn{
        padding: 0;
    } 
    .profile svg{
        vertical-align: baseline;
    }
    .profile i{
        align-items: center;
        display: flex;
    }
}

@media only screen and (min-width: 993px) {
    .profile-text{
        display: inline-block;
        margin-left: auto;
    }
    .profile-dropdown{
        margin-left: auto;
    }

    .profile{
        margin-left: auto;
    }
    .profile .svg-inline--fa {
        vertical-align: -0.15em;
    }
}

.profile-dropdown .dropdown-item.active, .profile-dropdown .dropdown-item:active {
    background-color: #979797 !important;
}