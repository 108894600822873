.card-header.bg-default {
    padding: 0;
    text-align: left;
    border: none;
    display: block;
    border-radius: 5px !important;
    position: relative;
    color: black;
    background: #d1d1d1 !important;
    text-transform: uppercase;
    padding-top: 0.4em !important;
    padding-bottom: 0.4em !important;
    margin-top: 6.4px !important;
    display: flex;
}

.mb-0.card-title {
    color: black;
    font-size: 18px;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    padding: 3.6px;
    padding-left: 25px;
    display: block;
    position: relative;
}

.mb-0.card-title::before {
    content: "\f139";
    font-family: "Font Awesome 5 Pro";
    font-size: 24px;
    position: absolute;
    left: 15px;
    top: 50%;
    line-height: 100%;
    margin-top: -12px; 
    visibility: hidden;
}

[aria-expanded="false"] .mb-0.card-title::before {
    content: "\f13a";
}

.mb-2.card.border {
    border: 0px !important;
}

.field-required::after {
    color: #666666;
    font-size: 14px;
}

.field-label {
    flex: 10 !important;
    color: black;
    text-align: end;
    font-size: 14px;
    margin-right: 5% !important;
}

.formio-errors {
    text-align: left;
    margin-left: 30% !important;
}

.field-content {
    flex: 40 !important;
    text-align: start;
    margin-right: 10%;
}

.form-radio {
    margin-top: 2%;
}

.formio-component-datagrid .field-label {
    flex: 10 !important;
    color: #666666;
    text-align: end;
    font-size: 14px;
    margin-right: 3% !important;
}

.formio-component-datagrid .field-content {
    flex: 40 !important;
    text-align: start;
    margin-right: 10%;
}

.btn-secondary {
    color: #fff;
    background-color: #007bff !important;
    border-color: #007bff !important;
}

.btn-secondary.formio-button-remove-row::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f057";
}

.fa.fa-times-circle-o {
    display: none;
}

.formio-component-datagrid {
    overflow: visible !important;
    height: auto !important;
}