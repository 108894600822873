.licence-config-year-input {
  padding-left: 0px;
}

.wizard-steps-container {
  display: flex;
}
.wizard-steps-container .ant-steps-item {
  flex-grow: 1;
  flex-basis: 0;
}
