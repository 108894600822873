.additional-application-download-link-details {
    font-size: 0.65rem;
    color: #676a6c;
    text-decoration: underline;
    right: 480px;
    margin-top: -30px;
    position: absolute;
    background-color: #fff;
    border-color: #fff;
    color: #4e555b;
}