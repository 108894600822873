/*************** Styling: Content - General ***************/

@media (max-width: 992px) {
    .container {
        max-width: 960px;
    }
}

/*************** Styling for Mobile View ***************/

@media only screen and (max-width: 992px) {
    .body-content {
        padding: 0;
        margin: 0;
    }
    .body-content .body-section-col {
        padding: 0;
        margin: 0;
    }
}

/*************** Styling: Workflow ***************/

.modal-remarks .modal-body .row {
    flex-direction: column;
}

.modal-remarks .modal-body .label-default {
    text-align: start;
    font-size: 0.85rem;
}

.modal-remarks .modal-body .label-error{
    text-align: start;
    font-size: 0.85rem;
}

.modal-remarks .modal-body textarea {
    display: block;
    width: 100% !important;
    height: 100px;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.modal-remarks .griddle-filter {
    display: none;
}

@media (min-width: 992px) {
    .modal-dialog.modal-history {
        max-width: 900px;
    }
}

.grid-container.report-generation {
    border: #dddddd 1px solid;
    border-top: none;
}

/*************** Styling: Label ***************/
.label-payment-item-total {
    margin-left: 5px;
    color: black;
    font-size: 0.8rem;
}

/****************** Additional Information Supporting Document CSS ***********************/
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

[dir=ltr] .documents-blog {
    display: table;
    margin: 10px -20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

[dir=ltr] .documents-cell.document-details {
    width: 50%;
    position: relative;
    padding-left: 50px;
}

[dir=ltr] .documents-cell.document-details.active::before {
    content: "\f058";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    color: #00853e;
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -11px;
    font-size: 22px;
    line-height: 100%;
}

[dir=ltr] .documents-cell {
    display: table-cell;
    vertical-align: middle;
    padding: 15px;
    text-align: left;
}

[dir=ltr] .documents-cell.document-action {
    background: #f7f7f7;
    border: 1px solid #ccc;
    width: 10%;
    text-align: center;
}

[dir=ltr] .documents-cell {
    display: table-cell;
    vertical-align: middle;
    padding: 15px;
    text-align: left;
}

[dir=ltr] .btn-bordered-small {
    border: 2px solid #bbb;
    padding-top: .250rem;
    padding-bottom: .250rem;
    color: #666;
    font-weight: 300;
    margin: 0 3px;
}

[dir=ltr] .upload-button {
    cursor: pointer;
}

[dir=ltr] .document-uploaded p {
    font-size: 13px;
    padding: 5px 0 5px 30px;
    position: relative;
    text-overflow: ellipsis;
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
}

[dir=ltr] .document-uploaded p {
    font-size: 13px;
    padding: 5px 0 5px 30px;
}

[dir=ltr] .file-pdf svg {
    content: "\f057";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    left: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    top: 50%;
    margin-top: -10px;
    color: #de1200;
    cursor: pointer;
}

.label-feedback {
    margin-left: 5px;
    font-weight: bold;
    color: #cc5965;
    font-size: 0.8rem;
    direction: ltr;
    direction: initial;
}

.additional-form-table-cell {
    white-space: pre;
}

.documents-cell>.name {
    font-size: medium;
    color: #3d6ac7;
}

.additional-form-download-template-link {
    padding-left: 235px;
    color: #005EB8;
}

.additional-form-modal-supporting-document-span {
    color: #ACACAC
}

.previous-form-btn {
    left: 50px;
    position: absolute;
}

.next-form-btn {
    right: 50px;
    position: absolute;
}

.licence-extension-table-cell {
    color: #5A8AF7;
    cursor: pointer;
}

.licence-extension-download-link {
    display: block;
    width: 70px;
    padding-top: .375rem;
    padding-bottom: .375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #5A8AF7;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
    cursor: pointer;
}

.center-item {
    align-items: center;
    justify-content: center;
}