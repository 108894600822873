.smart-content{
    margin: 0;
    padding: 0;
}

.background-content {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-top: 45px;
}

/*************** Styling: Forms - General ***************/
label.required:after {
    content: "*";
    color: #CC5965;
}

.griddle-table .griddle-table-heading .griddle-table-heading-cell.required:after {
    content: "*";
    color: #CC5965;
}

/*************** Styling: Forms - Labels ***************/
html[dir=ltr] .label-help {
    margin-left: 5px !important;
    margin-bottom: 3px !important;
    cursor: default !important;
    font-size: 0.8rem;
    color: #afafaf !important;
}
html[dir=rtl] .label-help {
    margin-right: 5px !important;
    margin-bottom: 3px !important;
    cursor: default !important;
    font-size: 0.8rem;
    color: #afafaf !important;
}

.label-default {
    text-align: end;
    font-weight: bold;
    font-size: 0.85rem;
    color: #676a6c;
}

html[dir=ltr] .label-error {
    text-align: right;
    font-weight: bold;
    font-size: 0.85rem;
    color: #CC5965;
}
html[dir=rtl] .label-error {
    text-align: left;
    font-weight: bold;
    font-size: 0.85rem;
    color: #CC5965;
}

html[dir=ltr] .label-feedback {
    margin-left: 5px;
    font-weight: bold;
    color: #CC5965;
    font-size: 0.8rem;
}
html[dir=rtl] .label-feedback {
    margin-right: 5px;
    font-weight: bold;
    color: #CC5965;
    font-size: 0.8rem;
}

.label-feedback:empty {
    display: none;
 }

@media only screen and (max-width: 992px) {

    html[dir=ltr] .label-default {
        text-align: left;
        font-size: 0.85rem;
    }
    html[dir=ltr] .label-error {
        text-align: left;
    }
    html[dir=rtl] .label-default {
        text-align: right;
        font-size: 0.85rem;
    }
    html[dir=rtl] .label-error {
        text-align: right;
    }
}


/*************** Styling: Forms - Inputs ***************/
.body-content .form-control{
    font-size: 14px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}

.body-content .form-control:focus{
    z-index: 0;
}

.grid-container .form-control{
    font-size: 14px;
}

.select-list > div{
    overflow: hidden;
    text-overflow: ellipsis;
}

.auto-complete > div {
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}

.form-control.text-area{
    white-space: normal;
}

.form-control.file-input-label-disabled{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

[dir=rtl] .form-control {
    text-align: right;
}

[dir=rtl] textarea,
[dir=rtl] input[type="text"] {
    text-align: right;
}

/*************** Styling: Forms - Checkbox ***************/
[dir=ltr] .checkbox-group {
    position: relative;
    margin-right: 15px;
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
[dir=rtl] .checkbox-group {
    position: relative;
    margin-left: 15px;
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox-control {
    display: none;
}

.checkbox-display {
    background-color: white;
    -ms-transform: scale(1); /* IE */
    -moz-transform: scale(1); /* FF */
    -webkit-transform: scale(1); /* Safari and Chrome */
    -o-transform: scale(1); /* Opera */
    padding: 10px;
    margin:0;
    cursor: pointer;
    -webkit-appearance: none;
    border: 2px solid #CCC;
    margin-bottom: 0px;
}

.checkbox-control:hover ~ .checkbox-display {
    border: 2px solid #0076C7;
}

.checkbox-control:checked ~ .checkbox-display {
    background-color: #0076C7;
    color: #0076C7 !important;
    border: 2px solid #0076C7;
    outline: none;
}

.checkbox-control:checked ~ .checkbox-display:after {
    content: "\2713";
    color: white;
    position: absolute;
    top: 0px;
    left: 3px;
    font-weight: bold;
}

.checkbox-label {
    margin-left: 10px;
    margin-bottom: 0px;
    /* display: inline; */
}


/*************** Styling: Forms - Radio Button ***************/
html[dir=ltr] .radio-group {
    position: relative;
    margin-right: 20px;
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
html[dir=rtl] .radio-group {
    position: relative;
    margin-left: 20px;
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio-control {
    display: none;
}

html[dir=ltr] .radio-display {
    background-color: white;
    width: 20px;
    height: 20px;
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 50%;
    border: 2px solid #ccc;
    margin-bottom: 0px;
    position: absolute;
    left: 0px;
}
html[dir=rtl] .radio-display {
    background-color: white;
    width: 20px;
    height: 20px;
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 50%;
    border: 2px solid #ccc;
    margin-bottom: 0px;
    position: absolute;
    right: 0px;
}

.radio-control:hover ~ .radio-display {
    border: 2px solid #0076C7;
}

.radio-control:checked ~ .radio-display {
    background-color: #0076C7;
    color: #0076C7 !important;
    border: 2px solid #0076C7;
    outline: none;
}

html[dir=ltr] .radio-control:checked ~ .radio-display:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
}
html[dir=rtl] .radio-control:checked ~ .radio-display:after {
    content: "";
    position: absolute;
    top: 4px;
    right: 4px;
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
}

html[dir=ltr] .radio-label {
    margin-left: 10px;
    margin-bottom: 0px;
}
html[dir=rtl] .radio-label {
    margin-right: 10px;
    margin-bottom: 0px;
}


/*************** Styling: Forms - Accordion Container ***************/
.accordion-wrapper{
    margin-bottom: 10px;
}

.form-content {
    clear: both;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background-color: #ffffff;
    color: inherit;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    border-top: 2px solid #3d6ac7;
    width: 100%;
}


html[dir=ltr] .form-title {
    cursor: pointer;
    font-weight: 100;
    color: #676a6c;
    padding: 5px 0px;
    margin: 0;
    font-size: 1.3rem;
    color: inherit;
    text-decoration: none;
    margin-left: 40px;
}
html[dir=rtl] .form-title {
    cursor: pointer;
    font-weight: 100;
    color: #676a6c;
    padding: 5px 0px;
    margin: 0;
    font-size: 1.3rem;
    color: inherit;
    text-decoration: none;
    margin-right: 40px;
}

.form-title:hover,.form-title:focus {
    color: #3d6ac7;
}

.form-group{
    margin-bottom: 23.5px;
    font-size: 0.85rem;
    text-align: start;
}
html[dir=ltr] .form-title-active {
    color: #3d6ac7;
    font-weight: 100;
    cursor: pointer;
    font-size: 1.3rem;
    padding-top: 10px;
    padding-bottom: 5px;
    margin: 0;
    margin-left: 40px;
}
html[dir=rtl] .form-title-active {
    color: #3d6ac7;
    font-weight: 100;
    cursor: pointer;
    font-size: 1.3rem;
    padding-top: 10px;
    padding-bottom: 5px;
    margin: 0;
    margin-right: 40px;
}
.panel-heading {
    padding: 16.5px 20px;
    border-bottom: 1px solid;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-color: #ddd;
    color: #333;
    background-color: #ffffff;
}
html[dir=ltr] .panel-heading > h2:before{
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-top: -0.1rem;
    margin-left: -1.2rem;
    margin-right: .7rem;
    transform: rotate(0deg);
    transition: all linear 0.25s;
}
html[dir=rtl] .panel-heading > h2:before{
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid currentColor;
    vertical-align: middle;
    margin-top: -0.1rem;
    margin-right: -1.2rem;
    margin-left: .7rem;
    transform: rotate(0deg);
    transition: all linear 0.25s;
}
html[dir=ltr] .panel-heading > h2.form-title-active:before{
    transform: rotate(90deg);
}
html[dir=rtl] .panel-heading > h2.form-title-active:before{
    transform: rotate(-90deg);
}
.icon-right {
    margin-right: 6px;
    font-size: 0.8em;
}

.hr-line {
    border-bottom: 1px solid #0076C7;
    padding-top: 10px;
}

.accordion-content {
    padding: 10px 30px;
    width: 100% !important;
}

.btn-download{
    margin-left: 25%;
}

@media only screen and (max-width: 992px) {
    .form-group {
        margin-bottom: 15px;
    }

    .form-content .label-default{
        padding-top: 0;
        padding-bottom: 0;
    }
    .form-content .plaintext{
        padding-top: 0;
        padding-bottom: 0;
    }
    .accordion-content {
        padding-top: 10px;
        padding-bottom: 0px;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .radio-label {
        font-size: 15px;
    }
    .btn-download{
        margin: unset;
        margin-bottom: 15px;
    }
}

.form-content .badge{
    /* background-color: rgb(65, 131, 215); */
    padding-top: 0.55em;
    padding-left: 0.7em;
    padding-right: 0.7em;
    font-weight: 500;
    margin-right: 2px;
    margin-top: 4.5px;
    font-size: 0.84rem;
}

/* Fixes Jumpy Accordion issue */
/* .clearfix {
    zoom: 1;
}

.clearfix:before,
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
    clear: both;
} */


/*************** Styling: Grid Container ***************/
.grid-container{
    padding: 20px;
}


/*************** Styling: Datetimepicker padding for time ***************/
.datepicker {
    position: relative;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group {
    flex-wrap: nowrap !important;
}

.rw-input {
    height: 38px !important;
}

.datetimepicker .rw-select {
    position: absolute !important;
    overflow: hidden !important;
    top: 0 !important;
    /* right: 0 !important; */
    width: 40px !important;
}

[dir=ltr] .datetimepicker .rw-select {
    right: 0 !important;
}

[dir=rtl] .datetimepicker .rw-select.rw-select-bordered {
    left: 0 !important;
    border-right: #ccc 1px solid;
} 

.datetimepicker .rw-select .rw-btn{
    width: 100%;
}

.datepicker .rw-i {
    padding-left: 5px;
}

.datepicker .rw-select {
    position: absolute !important;
    overflow: hidden !important;
    top: 0 !important;
    right: 0 !important;
    width: 60px !important;
}

.clearField{
    color: #aaa;
    font-size: 16px;
    cursor: pointer;
}

.clearField .fa-times {
    font-size: 15px;
    color: #cccccc;
    position: absolute;
    top: 12px;
    text-align: right;
}

[dir=ltr] .clearField .fa-times {
    right: 70px;
}

[dir=rtl] .clearField .fa-times {
    left: 70px;
}

.datetimepicker .clearField .fa-times:focus {
    font-size: 15px;
    color: #999999;
    position: absolute;
    top: 12px;
    right: 68px;
    text-align: right;
}

.rw-widget-picker{
    height: 2.6em !important;
}

.rw-datetime-picker input[readonly]{
    cursor: pointer;
}
/*************** Styling: Antd - Number Picker ***************/
.ant-input-number-sm {
    padding: 7px !important;
    height: 38px !important;
}

.ant-input-number{
    border: 1px solid #ccc !important;
}

.appendBack {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.appendFront {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}


/*************** Styling: InputGroup ***************/

.inputgroup-btn {
    background: white;
    color: #AAA;
    cursor: pointer;
}

.inputgroup-btn:hover {
    background: #EEE;
}

.inputgroup-label {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #E9ECEF;
    border: 1px solid #CED4DA;
    border-radius: .25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    cursor: pointer;
}

/*************** Styling: Cursor ***************/

.cursor-no-drop {
    cursor: no-drop;
}

/*************** Styling: Toaster ***************/
.toaster-icon {
    margin-right: 10px;
}

.s-alert-top {
    padding-left: 29px !important;
}

.s-alert-success {
    background: #5ca75e !important;
}

.s-alert-error {
    background: #CC5965 !important;
}


/*************** Styling: FileUpload ***************/
.file-input {
    display: none;
    padding: 10px 10px 10px 10px;
    border: 1px solid #CED4DA;
    border-radius: 4px;
}

.file-input-label-pointer {
    cursor: pointer !important;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}

.file-input-label-disabled {
    cursor: no-drop !important;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}

.download-link {
    float: right;
    font-size: 0.85em;
    color: #676a6c;
    text-decoration: underline;
    padding-top: 5px;
    margin-bottom: 0px;
    cursor: pointer;
}

.download-link:hover {
    text-decoration: underline;
}

.download-link-details {
    padding-top: 0.375rem;
    padding-bottom: .375rem;
    font-size: 0.85rem;
    line-height: 2.5;
    color: #676a6c;
    text-decoration: underline;
}

.download-link-details:hover {
    text-decoration: underline;
}

/*************** Styling: Wizard ***************/
.wizard-container {
    margin-top: 40px;
    border-top: 2px solid #3d6ac7;
}

.wizard-container .row {
    margin-top: 25px;
    margin-bottom: 30px;
}

.wizard-content {
    margin-left: 30px;
    margin-right: 30px;
}

.wizard-steps-container .wizard-flow{
    display: flow-root;
}

.wizard-steps-container .wizard-flex{
    display: flex;
}

.ant-steps-item-title {
    padding-right: 8px;
}
.ant-steps-item-title:after {
    display: none;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin: 10px;
}

.anticon svg {
    margin-bottom: 7px;
}

[dir=rtl] .ant-steps-item-icon {
    margin-left: 10px;
    margin-right: 0px;
}

@media (max-width: 992px) {

    .tab-pane.wizard-container.form-content {
        border-top: 1px solid #d3d3d3;
    }

    .wizard-steps-container {
        margin: 0px 5px;
    }

    .wizard-step-group{
        justify-content: center;
        margin: auto;
    }

    .ant-steps-horizontal.ant-steps-label-horizontal {
        display: block;
    }

    .ant-steps-item-icon, .ant-steps-item-content {
        vertical-align: middle;
        /* width: 82%; */
    }

    .anticon svg {
        margin-bottom: 7px;
    }

    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
        margin: 0px;
    }

    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
        display: block;
        overflow: visible;
        justify-content: center;
        margin: auto;
        width: 100%;
    }

    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
        overflow: hidden;
        margin-top: auto;
        min-height: 0;
    }

    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
        margin-right: auto;
        white-space: nowrap;
    }

    .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-tail {
        display: block;
    }

    .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
        position: absolute;
        left: 32px;
        top: 0;
        height: 100%;
        width: 1px;
        /*without descriptions*/
        padding: 2.6rem 0 6px;

        /*with descriptions*/
        /* padding: 2.85rem 0 6px !important;  */
    }

    .ant-steps-item-tail:after {
        content: '';
        display: inline-block;
        background: #e8e8e8;
        height: 1.7rem;
        border-radius: 1px;
        width: 100%;
        -webkit-transition: background .3s;
        -o-transition: background .3s;
        transition: background .3s;
    }

    .ant-steps-item-title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.65);
        display: inline-block;
        padding-right: 16px;
        position: relative;
        line-height: 32px;
    }

    .ant-steps-item.wizard-step {
        display: block;
        min-height: 48px;
    }

    .ant-steps-item .ant-steps-item-icon {
        margin: 10px 16px;
    }

    .ant-steps-item-title:after {
        display: none;
    }

    .ant-steps-item.ant-steps-item-process.wizard-step {
        font-weight: 400;
        background-color: #C7E2FF;
    }
    .ant-steps-item-process .ant-steps-item-icon {
        border-color: #fff;
    }
    .ant-steps-item:not(:first-child).ant-steps-item-process .ant-steps-item-tail {
        background-color: #1890ff;
    }
    .ant-steps-item:last-child.ant-steps-item-process .ant-steps-item-tail {
        background-color: #1890ff;
        display: block;
    }
    .ant-steps-item:last-child.ant-steps-item-process .ant-steps-item-tail:after {
        background-color:#F8F8F8;
    }
    .wizard-container {
        margin: 3px 0px;
    }
    .wizard-container .row {
        margin-top: 20px;
        margin-bottom: 25px;
    }
    .wizard-content{
        margin: 0px 30px;
    }
    .panel-card .start-card-body > .smart-content > .row > .body-content .wizard-container.form-content {
        margin-bottom: 0px;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
        min-width: 100%;
        padding-bottom: 3px;
        margin-top: -6px;
    }
    .ant-steps-item.ant-steps-item-error{
        font-weight: 500;
        background-color: #ffd3d7;
    }
    .ant-steps-item.ant-steps-item-error .ant-steps-item-tail{
        display: none;
    }
}

@media (max-width: 480px) {
    .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
        left: 32px;
         /*without descriptions*/
        padding: 2.6rem 0 6px;

        /*with descriptions*/
        /* padding: 2.85rem 0 6px !important; */
    }
    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
        display: inline-block;
        min-height: 0;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
        float: none;
    }
}

/*************** Styling: Tabs ***************/
.tab-header {
    cursor: pointer;
}

.tab-container {
    background: white;
    padding: 30px 20px 30px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #DEE2E6;
};


.card-header {
    padding: 0.7rem 0.6rem;
    margin-bottom: 0;
}

.tab-nav-link {
    display: block;
    padding: 0.9rem 1.5rem;
}

a:not([href]):not([tabindex]).tab-nav-link.active { /*overwrite bootstrap css */
    /* color: #495057; */
}

.help-block {
    font-style: normal;
    font-size: 0.9rem;
    font-weight: 600;
    color: #CC5965;
    white-space: nowrap;
}

.tab-nav-tabs .tab-nav-item {
    margin-bottom: -1px;
}

.profile-dropdown-item {
    text-decoration: none;
    display: none;
    width: 100%;
    padding: 0rem 1.5rem;
    padding-bottom: 0.5rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border-bottom: 1px solid #bdc3c7;
}

@media (max-width: 992px) {

    .tab-container {
        border-top: 1px solid #E8E8E8;
    }

    .nav-tabs .nav-link {
        border: 1px solid transparent;
    }

    .tab-nav-tabs .tab-nav-item {
        width: 100%;
        text-align: center;
    }

        .tab-nav-tabs .tab-nav-link.active, .tab-nav-tabs .tab-nav-item.show .tab-nav-link {
            background-color: #C7E2FF;
            border-top-color: rgb(65, 131, 215);
            border-top-width: 2px;
        }

    .card-header {
        padding: 0.7rem 0.6rem;
        margin-bottom: 0;
        margin-top: 5px;
    }

    .profile-dropdown-item {
        text-decoration: none;
        display: block;
        width: 100%;
        padding: 0rem 1.5rem;
        padding-bottom: 0.5rem;
        margin-bottom: 3px;
        clear: both;
        font-weight: 400;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border-bottom: 1px solid #bdc3c7;
    }

    .tab-container {
        padding: 20px 35px 20px 40px;
        margin-bottom: 0px;
    }
}
@media  (max-width: 992px) {

    .tab-container {
        padding: 20px 30px 20px 30px;
        margin-bottom: 0px;
    }

}


/*************** Styling: Autocomplete ***************/
.autocomplete {
    color: #ccc;
    height: 24px;
    width: 32px;
}

.DropdownIndicator {
    color: '#ccc';
    height: 24;
    width: 32;
}


/*************** Styling: Plaintext ***************/
.plaintext {
    font-size: 0.85rem;
    outline: None;
    word-wrap: break-word;
}


/*************** Styling: Paragraph ***************/
.paragraph-char {
    float: right;
    font-size: 0.8em;
    color: #676a6c;
    padding-top: 5px;
    margin-bottom: 0px;
}


/*************** Styling: Badges ***************/
.badge {
    padding: 0.4em .4em !important;
    /* background-color: rgb(65, 131, 215); */
    padding-top: 0.55em;
    padding-left: 0.7em;
    padding-right: 0.7em;
    font-weight: 500;
    /* margin-right: 2px; */
    margin-top: 0px;
    font-size: 80%;
}


/*************** Styling: Empty Supporting Docs Section ***************/
.supportingDocs-empty {
    text-align: center;
}


/*************** Styling: Applcation Details Section - Table ***************/
.success-table {
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.success-table-body td {
    background-color: #fff !important;
}

.success-table-item {
    width: 20%;
}

.success-table-detail {
    width: 80%;
    word-break: break-word;
}

/*************** Styling: New BirthCert Checkbox ***************/
.disclaimer-checkbox .label-default {
    padding-right: 0px;
}


/*************** Styling: React-select field ***************/
.css-1aya2g8 {
    background-color: #ffffff !important;
}

.disclaimer-checkbox div .label-feedback {
    margin-left: 0px;
}

.disclaimer-checkbox .label-error {
    padding-right: 0px;
}

.disclaimer-checkbox .checkbox-control:checked ~ .checkbox-display:after {
    content: "\2713";
    color: white;
    position: absolute;
    top: 0px;
    left: 3px;
    font-weight: bold;
}

@media only screen and (max-width: 992px) {

    .disclaimer-checkbox .label-error {
        text-align: left;
    }

}

/**************************** Styling: Card  ****************************/
.start-card-body {
    flex: 1 1 auto;
    font-size: 16px;
    padding: 0;
}

.start-card-body-content {
    padding-top: 1.25em;
    padding-bottom: 2em;
    padding-left: 4em;
    padding-right: 4em;
}

.start-card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.125);
}

@media only screen and (max-width: 992px) {

    .start-card-body-content {
        padding-top: 1.25em;
        padding-bottom: 2em;
        padding-left: 1.9em;
        padding-right: 1.9em;
    }

}

ul.SMARTListGroup.list-group {
    list-style: disc;
    margin-bottom: 20px;
}

.SMARTListGroup .list-group-item {
    display: list-item;
    margin-left: 30px;
    padding-left: 0px;
    border: 0px;
    background-color: transparent;
    margin-bottom: -10px;
}


/*************** Styling: Form Alert ***************/
.form-alert-container{
    margin-right: 5px;
    margin-left: 5px;
}

@media only screen and (max-width: 992px){
    .form-alert-container{
        margin-right: -15px;
        margin-left: -15px;
    }
}
.form-alert-content {
    width: 100%;
    /* min-height: 50px; */
    background-color: #DA5C5C;
    color: white;
    display: flex;
    align-items: center;
    padding: 13px;
    margin-top: 5px;
    border-radius: 5px;
}

.form-alert-icon {
    padding: 0px 20px;
}


/*************** Styling: ToggleSwitch ***************/
/* Temp fix for col-form-label padding alternative */
.toggle-switch-group {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
}

.toggle-switch-control {
    display: none;
}

.toggle-switch-display {
    display: block;
    position: relative;
    background: grey;
    cursor: pointer;
    width: 50px;
	height: 25px;
	border-radius: 100px;
    margin-bottom: 0px;
}

.toggle-switch-display:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 15px;
	height: 15px;
	background: white;
	border-radius: 90px;
    transition: 0.3s;
}

.toggle-switch-control:checked ~ .toggle-switch-display {
	background: #4183D7;
}

.toggle-switch-control:checked ~ .toggle-switch-display:after {
    transform: translateX(100%) translateX(10px);
}

.toggle-switch-label {
    margin-left: 10px;
    margin-bottom: 0px;
    font-weight: bold;
}


/************************ Styling for First Layout ************************/
/********* Accordion Container *********/
.form-title:hover {
    /* color: #005F9F; */
}

html[dir=ltr] .form-content > .panel-heading > .form-title-active {
    /* color: #005F9F; */
    font-weight: 100;
    cursor: pointer;
    font-size: 1.3rem;
    padding: 5px 0px;
    margin: 0;
    margin-left: 40px;
}
html[dir=rtl] .form-content > .panel-heading > .form-title-active {
    /* color: #005F9F; */
    font-weight: 100;
    cursor: pointer;
    font-size: 1.3rem;
    padding: 5px 0px;
    margin: 0;
    margin-right: 40px;
}

.body-content > .form-content > .panel-heading {
    padding: 16.5px 20px;
    border-bottom: 1px solid;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-color: #ddd;
    color: #333;
    background-color: #ffffff;
}


/********* Tabs *********/
.nav-tabs .nav-link {
    /* border: 1px solid transparent; */
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-top: 0px important;
    border-right-color: rgb(222, 226, 230);
    border-bottom-color: rgb(255, 255, 255);
    border-left-color: rgb(222, 226, 230);
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-right-color: rgb(233, 236, 239);
    border-bottom-color: rgb(222, 226, 230);
    border-left-color: rgb(233, 236, 239);
}

.tab-nav-tabs .tab-nav-link.active, .tab-nav-tabs .tab-nav-item.show .tab-nav-link {
    color: #495057;
    background-color: #fff;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom-color: #fff;
    border-top: 0px solid transparent important;
    /*border-top-color: rgb(65, 131, 215);
    border-top-width: 2px; */
    font-size: 1rem;
    font-weight: 500;
}

.tab-nav-tabs .tab-nav-link {
    font-size: 1rem;
    font-weight: 500;
}


/********* Card *********/
.start-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin-bottom: 0px;
    border-top: 2px solid #3d6ac7;
}



@media only screen and (max-width: 992px) {

    .tab-nav-tabs .tab-nav-link {
        color: #007bff;
        font-size: 1rem;
        font-weight: 500;
        background-color: #fff;
    }
}


/*************** Styling for Second Layout ***************/
/********* Accordion Container *********/
.panel-card .start-card-body > .smart-content > .row > .body-content .form-content {
    /* border-top: 2px solid #3d6ac7; */
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background-color: #ffffff;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    margin-bottom: 30px;
}

.panel-card .start-card-body > .smart-content > .row > .body-content > .form-content > .panel-heading > .form-title:hover {
    /* color: #3d6ac7; */
}

html[dir=ltr] .panel-card .start-card-body > .smart-content > .row > .body-content > .form-content > .panel-heading > .form-title-active {
    /* color: #3d6ac7; */
    font-weight: 100;
    cursor: pointer;
    font-size: 1.3rem;
    padding: 5px 0px;
    margin: 0;
    margin-left: 40px;
}
html[dir=rtl] .panel-card .start-card-body > .smart-content > .row > .body-content > .form-content > .panel-heading > .form-title-active {
    /* color: #3d6ac7; */
    font-weight: 100;
    cursor: pointer;
    font-size: 1.3rem;
    padding: 5px 0px;
    margin: 0;
    margin-right: 40px;
}

.panel-card .start-card-body > .smart-content > .row > .body-content > .form-content > .panel-heading {
    padding: 16.5px 20px;
    border-bottom: 1px solid;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-color: #ddd;
    color: #676a6c;
    background-color: #ffffff;
}


/********* Tabs *********/
.panel-card .start-card-body > .smart-content > .row > .body-content > .tab-nav-tabs .tab-nav-link.active, .tab-nav-tabs .tab-nav-item.show .tab-nav-link {
    /* color: #495057; */
    background-color: #fff;
    /* border-color: #dee2e6 #dee2e6 #fff;
    border-top: 2px solid #3d6ac7; */
    font-size: 1rem;
    font-weight: 500;
}

.panel-card .start-card-body > .smart-content > .row > .body-content > .tab-nav-tabs .tab-nav-link {
    /* color: #3d6ac7; */
    font-size: 1rem;
    font-weight: 500;
}


/********* Card *********/

.panel-card .start-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin-bottom: 0px;
    /* border-top: 2px solid #3d6ac7; */
}

.panel-card .start-card-body > .smart-content > .row > .body-content > .start-card > .start-card-body > .start-card-body-content {
    padding: 10px 30px;
}

.panel-card .start-card-body > .smart-content > .row > .body-content > .start-card > .start-card-body > .title-heading {
    display: block;
    color: #333;
    margin: 30px;
    font-size: 24px;
    font-weight: 400;
}

/* for success page */
.panel-card .start-card-body > .row > .body-content > .start-card > .start-card-body > .title-heading {
    display: block;
    color: #333;
    margin: 30px;
    font-size: 24px;
    font-weight: 400;
}
/* for success page */
.panel-card .start-card-body > .row > .body-content > .start-card > .start-card-body > .start-card-body-content {
    padding: 10px 30px;
}

@media only screen and (max-width: 992px) {

    /********** Tab **********/

    .panel-card .start-card-body > .smart-content > .row > .body-content > .form-content {
        /* border-top: 2px solid #3d6ac7; */
        border-top: 1px solid #dddddd !important;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        background-color: #ffffff;
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
        margin-top: 10px;
        margin-bottom: 10px;
    }

    /* for success page */
    .panel-card .start-card-body > .row > .body-content > .start-card > .start-card-body > .start-card-body-content {
        padding: 0px 30px;
    }

    /* for success page */
    .panel-card .start-card-body > .row > .body-content > .start-card > .start-card-body > .title-heading {
        display: block;
        color: #333;
        margin: 30px;
        font-size: 22px;
        font-weight: 400;
    }



    .panel-card .start-card-body > .smart-content > .row > .body-content > .start-card > .start-card-body > .title-heading {
        display: block;
        color: #333;
        margin: 30px;
        font-size: 22px;
        font-weight: 400;
    }


    .ant-divider-horizontal.ant-divider-with-text, .ant-divider-horizontal.ant-divider-with-text-left, .ant-divider-horizontal.ant-divider-with-text-right {
        white-space: initial !important;
    }
}

/*************** Styling: Forms - Scroll to Top ***************/
.scrollToTop.fadeToBottom {
    -webkit-animation-name: fadeToBottom;
    animation-name: fadeToBottom;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.scrollToTop {
    color: #ffffff;
    position: fixed;
    bottom: 5px;
    right: 5px;
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1005;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    filter: alpha(opacity=0);
}
.scrollToTop svg {
    font-size: 38px;
    margin-top: 4px;
}
.circle {
    -webkit-border-radius: 100% !important;
    -moz-border-radius: 100% !important;
    border-radius: 100% !important;
}
.scrollToTop:hover {
    background-color: rgba(0, 0, 0, 0.6);
}
.scrollToTop.fadeToBottom {
    -webkit-animation-name: fadeToBottom;
    animation-name: fadeToBottom;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    bottom: -50px;
}

/*************** Styling: Forms - Password - Show/Hide ***************/
.password-container {
    position: relative;
}

:root {
    --show-hide-password-top: 5px;
}

.show-hide-password {
    cursor: pointer;
    position: absolute;
    background: #727272;
    color: white;
    padding: 0px 8px;
    border-radius: 4px;
    font-weight: 700;
    font-size: .7em;
    height: 27px;
    top: var(--show-hide-password-top);
    right: 4px;
    padding-top: 7px;
}

/************ Tab  ************/

.tab-header.tab-nav-link.active{
    border-top : 2px solid #3d6ac7;
}

@media (max-width: 992px) {
    .tab-header.tab-nav-link.active{
        background-color: #C7E2FF;
    }
}

.tab-header.tab-nav-link:not(.active){
    color : #3d6ac7;
}

/************ CreatableSelectList FormGroup  ************/
.csl-Form-Group {
    margin-bottom: 0px;
}

/***********Any text that need to show as link*******************/
.application-link {
    color: #0772b8;
    cursor: pointer;
}

.application-link:hover {
    text-decoration: underline;
}

.div-table-link{
    display: inline-block;
}