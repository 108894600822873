/*************** Styling: Profile ***************/
.profile{
    color: #000000;
    display: flex;
    align-self: stretch;
}

.profile a{
    color: #000000;
}

@media only screen and (max-width: 992px) {
    /*************** Styling: App - Site Container ***************/

    .site-container{
        padding-left: 15px;
        padding-top: 50px;
        padding-right: 15px;
        padding-bottom: 15px;
    }


    /*************** Styling: Content - General ***************/

    .body-content {
        padding: 0;
        margin: 0;
    }
        
    .panel-card .body-content {
        margin: 0px 0px 0px 0px;
    }

    .admin-content-wrapper{
        padding-bottom: 60px;
    }

    .site-container > .smart-content > .body-content {
        margin-left: -15px;
        margin-right: -15px;
        overflow: visible;
    }

    .panel-card > .start-card-body > .smart-content > .body-content {
        margin: 0px -15px !important;
    }

    .background-content {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 93vh;
        padding-top: 0px;
        padding-bottom: 0px;
        overflow: hidden;
    }

    .collapse:not(.show) {
        display: none;
    }

    
    .site-container .smart-content .row.body-content .col .form-content  {
        clear: both;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        background-color: #ffffff;
        color: inherit;
        border-radius: 0px;
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
        margin: 10px 0px;
    }

/*************** Styling: Grid Container ***************/

.grid-container{
    padding: 0px;
}

html[dir=ltr] .panel-card .grid-container {
    margin-left: 0px;
    font-size: 0.85rem;
}
html[dir=rtl] .panel-card .grid-container {
    margin-right: 0px;
    font-size: 0.85rem;
}
/*************** Styling: Tab ***************/

.panel-card .start-card-body > .smart-content > .row > .body-content > .tab-nav-tabs .tab-nav-link.active, .tab-nav-tabs .tab-nav-item.show .tab-nav-link {
    color: #495057;
    /* background-color: #C7E2FF; */
    font-size: 1rem;
    font-weight: 500;
    border-radius: 0rem;
    /* border-top: 2px solid #3d6ac7; */
}

/*************** Styling: Card ***************/


.tab-nav-tabs .tab-nav-link.active, .tab-nav-tabs .tab-nav-item.show .tab-nav-link {
    color: #495057;
    background-color: #C7E2FF;
    border-color: #fff;
    border-top: 2px solid rgb(65, 131, 215);
    font-size: 1rem;
    font-weight: 500;
}

.site-container > .smart-content > .row > .body-content > .start-card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0rem;
    /* border-top: 2px solid rgb(65, 131, 215); */
}

/* for success page*/
.site-container > .row > .body-content > .start-card > .start-card-body > .title-heading {        
    display: block;
    padding: 20px;
    padding-left: 1.2em;
    padding-right: 1.2em;
    border-bottom: 0.5px dashed;
    border-color: #ddd;
    color: #333;
    background-color: #ffffff;
    margin-top: 0.1rem;
    font-size: 22px;
    font-weight: 400;
}

.site-container > .smart-content > .row > .body-content > .start-card > .start-card-body > .title-heading {
    display: block;
    padding: 20px;
    padding-left: 1.3em;
    padding-right: 1.3em;
    border-bottom: 0.5px dashed;
    border-color: #ddd;
    color: #333;
    background-color: #ffffff;
    margin-top: 0.1rem;
    font-size: 24px;
    font-weight: 400;
}

.site-container > .smart-content > .row > .body-content > .start-card > .start-card-body > .start-card-body-content {
    padding-top: 1.25em;
    padding-bottom: 4em;
    padding-left: 1.9em;
    padding-right: 1.9em;
}

.site-container > .smart-content > .row > .body-content > .form-content {
    border-color: #ddd;
    /* border-top: 2px solid rgb(65, 131, 215); */
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background-color: #ffffff;
    color: inherit;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    margin-top: 10px;
    margin-bottom: 10px;
}

.panel-card .start-card-body > .smart-content > .row > .body-content > .start-card > .start-card-body > .title-heading {
    display: block;
    color: #333;
    margin: 30px;
    font-size: 22px;
    font-weight: 400;
}

.panel-card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #F8F8F8;
    background-clip: border-box;
    border: 0px;
    border-radius: 0rem;
    margin: auto;
    margin-bottom: 55px;
    margin-top: 0px;
    width: 100%;
}

/*************** Styling: Page Footer ***************/

.page-footer {
    width: 100%;
}

html[dir=ltr] .panel-card .page-footer {
    border-top: 1px solid #ddd;
    background-color: #fff;
    text-align: center;
    position: fixed;
    bottom: 0;
    height: 60px;
    z-index: 2;
    width: 100%;
    margin: auto;
    margin-left: 15px;
}
html[dir=rtl] .panel-card .page-footer {
    border-top: 1px solid #ddd;
    background-color: #fff;
    text-align: center;
    position: fixed;
    bottom: 0;
    height: 60px;
    z-index: 2;
    width: 100%;
    margin: auto;
    margin-right: 15px;
}

/*************** Styling for First Layout ***************/
.vertical .first.body-title-text {
    padding: 5px;
}

.vertical .first.sticky-title{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 52px;
}
/*************** Styling: Navbar ***************/

   .nav-header-logo{
        display: none;
    }
    .navbar-footer{
        display: none;
    }
  
    .layout-1m {
        position: fixed;
        top: 50px;
        width: 100%;
        overflow-y: scroll;
        z-index: 3;
        opacity: 1;
        background-color: #3d6ac7;
        background-image: url("../../assets/images/sidebartest12.png");
        background-repeat: no-repeat;
        background-size: cover;
        color: #DDD !important;
        padding: 0;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    }

    html[dir=ltr] .layout-1m-container {
        width: 100%;
        display: flex;
        left: 0px;
        margin-left: 0px;
    }
    html[dir=rtl] .layout-1m-container {
        width: 100%;
        display: flex;
        right: 0px;
        margin-right: 0px;
    }

    .layout-1m-container .nav{
        width: 100%;
    }
    html[dir=ltr] .layout-1m-container .navbar-item {
        flex-direction: row;
        display: flex;
        align-items: center;
        white-space: normal;
        color: #FFFFFF;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
        padding: 10px 22px 10px 11px;
    }
    html[dir=rtl] .layout-1m-container .navbar-item {
        flex-direction: row;
        display: flex;
        align-items: center;
        white-space: normal;
        color: #FFFFFF;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
        padding: 10px 11px 10px 22px;
    }
    
    html[dir=ltr] .layout-1m-container .navbar-item.active {
        width: 100%;
        font-weight: bold;
        border-left: 4px solid #011D75;
        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 2px 0px 0 rgba(0, 0, 0, 0.19);
        color: #FFFFFF;
        text-decoration: none;
        background-color: rgba(255,255,255,0.2) !important;
    }
    html[dir=rtl] .layout-1m-container .navbar-item.active {
        width: 100%;
        font-weight: bold;
        border-right: 4px solid #011D75;
        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 2px 0px 0 rgba(0, 0, 0, 0.19);
        color: #FFFFFF;
        text-decoration: none;
        background-color: rgba(255,255,255,0.2) !important;
    }

    .layout-1m-container .navbar-item:hover, .navbar-item:focus {
        width: 100%;
        font-weight: bold;
        color: #FFFFFF;
        text-decoration: none;
        background-color: rgba(255,255,255,0.4) !important;
    }
    .layout-1m-container .nav-collapse {
        width: 100%;
    }
    .layout-1m-container .navbar-item-icon {
        margin: 1rem;
    }
    html[dir=ltr] .layout-1m-container .dropdownlevel-1{
        padding-left: 25px !important;
    }
    html[dir=ltr] .layout-1m-container .dropdownlevel-2{
        padding-left: 50px !important;
    }
    html[dir=ltr] .layout-1m-container .dropdownlevel-3{
        padding-left: 75px !important;
    }
    html[dir=rtl] .layout-1m-container .dropdownlevel-1{
        padding-right: 25px !important;
    }
    html[dir=rtl] .layout-1m-container .dropdownlevel-2{
        padding-right: 50px !important;
    }
    html[dir=rtl] .layout-1m-container .dropdownlevel-3{
        padding-right: 75px !important;
    }
}

@media only screen and (min-width: 1065px){
    html[dir=ltr] .panel-card .page-footer {
        border-top: 1px solid #ddd;
        background-color: #fff;
        text-align: center;
        position: fixed;
        bottom: 0;
        width: calc(100vw - 270px);
        height: 60px;
        z-index: 2;
        margin-left: -3.3%;
    }

    .html[dir=rtl] panel-card .page-footer {
        border-top: 1px solid #ddd;
        background-color: #fff;
        text-align: center;
        position: fixed;
        bottom: 0;
        width: calc(100vw - 270px);
        height: 60px;
        z-index: 2;
        margin-right: -3.3%;
    }

}