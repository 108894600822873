.dashboard-container .bg-main {
    background-color: #3d6ac7;
}

.dashboard-container .bg-second {
    background-color: #011D75;
}

.dashboard-container .card {
    margin-bottom: 15px;
}

.dashboard-container .card-col {
    display: flex;
}

.dashboard-container .carousel {
    margin-bottom: 15px;
}

.dashboard-container .carousel-caption h3, .dashboard-container .carousel-caption p {
    color: #FFFFFF;
}

.dashboard-container .dashboard-table {
    margin-bottom: 15px;
}

.dashboard-container .dashboard-table-stats {
    margin-bottom: 15px;
}

html[dir=ltr] .dashboard-container .nc-card {
    border-left: 8px solid #528df7;
}
html[dir=ltr] .dashboard-container .urgent-nc {
    border-left: 8px solid #ffb922;
}
html[dir=ltr] .dashboard-container .overdue-nc {
    border-left: 8px solid #ff4949;
}
html[dir=rtl] .dashboard-container .nc-card {
    border-right: 8px solid #528df7;
}
html[dir=rtl] .dashboard-container .urgent-nc {
    border-right: 8px solid #ffb922;
}
html[dir=rtl] .dashboard-container .overdue-nc {
    border-right: 8px solid #ff4949;
}

.dashboard-container .nc-card:hover {
    background-color: #f6fcff;
}

.dashboard-container .nc-number {
    font-size: 30px;
    font-weight: bold
}

.dashboard-container .dashboard-table thead {
    background-color: rgb(85, 120, 245);
    color: white;
    font-size: 16px;
}

.dashboard-container .dashboard-table-stats thead {
    background-color: rgb(85, 120, 245);
    color: white;
    font-size: 16px;
}

.dashboard-container .dashboard-table td {
    font-size: 15px;
    font-weight: bold
}

.dashboard-container .dashboard-table-stats td {
    font-size: 15px;
    font-weight: bold
}

.dashboard-container .dbchartcard {
    text-align: center;
    border: none;
}

.dashboard-container .tlc-card {
    align-content: center;
    height: 95%;
    width: fit-content;
    text-align: center;
    display: unset;
    border: none;
}

.dashboard-container .tlc-header {
    text-shadow: #EDEDED 3px 2px 0;
}

.dashboard-container .cc-header {
    text-shadow: #EDEDED 3px 2px 0;
}

.dashboard-container .dbcardheader {
    font-size: 30px;
    background: white;
    color: #3d6ac7;
    text-shadow: #dddddd 3px 2px 0;
}

.dashboard-container .dbcardtext {
    font-size: 18px;
}

.dashboard-container .qodcard {
    margin-bottom: 15px;
    border: none;
}

.dashboard-container .blockquote-footer {
    font-size: 14px;
}

.dashboard-container .dbdata-row {
    margin-bottom: 28px;
}

.dashboard-container .dbdata-bdm td {
    font-size: 30px;
    font-weight: bolder;
    background-color: rgb(245, 245, 245);
    width: 95%;
    height: 50px;
    color: #375ace;
    padding-bottom: 5px;
}

.dashboard-container tbody {
    overflow-y: scroll;
}

.dashboard-container .dbdata-bdm .table-subtitle {
    border: none;
    font-size: 12px;
    padding: 0;
    font-weight: normal;
    color: black;
}

.dashboard-container .dbdata-bdm img {
    padding: inherit;
    padding-top: 30%;
}

.dashboard-container .db-nbcard {
    text-align: center;
    font-size: 15px;
    border-radius: 0rem;
}

.db-nbcard .card-body {
    padding: 20px 1.5px;
}

.db-nbcard .card-header {
    font-size: 20px;
}

.db-nbcard .nbcard-auth {
    background: #e4f2ff;
}

.db-nbcard .priority-nbcard-auth {
    background: #ffdbdb;
}

.dashboard-container .card-header {
    background-color: #577eff
}

.dashboard-container .db-divider {
    font-size: 23px;
}

.dashboard-container .stats-col {
    box-shadow: 0px 0px 3px 0px rgb(228, 228, 228);
    padding: 15px;
}

.dashboard-container .stats-col-bar-chart {
    box-shadow: 0px 0px 3px 0px rgb(228, 228, 228);
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
}

.dashboard-container .rv-discrete-color-legend-item__title
{
    font-size:15px;
}

@media only screen and (max-width: 500px) {
    .dashboard-container .dashboard-table-stats {
        display: block;
        overflow-x: scroll;
    }
}

html[dir=ltr] .dashboard-container .app-today-nc {
    border-left: 8px solid #5C88C5;
}
html[dir=ltr] .dashboard-container .pending-app-nc {
    border-left: 8px solid #528DF7;
}
html[dir=ltr] .dashboard-container .licence-today-nc {
    border-left: 8px solid #56C4C5;
}
html[dir=ltr] .dashboard-container .revenue-today-nc {
    border-left: 8px solid #ADD68A;
}
html[dir=rtl] .dashboard-container .app-today-nc {
    border-right: 8px solid #5C88C5;
}
html[dir=rtl] .dashboard-container .pending-app-nc {
    border-right: 8px solid #528DF7;
}
html[dir=rtl] .dashboard-container .licence-today-nc {
    border-right: 8px solid #56C4C5;
}
html[dir=rtl] .dashboard-container .revenue-today-nc {
    border-right: 8px solid #ADD68A;
}

html[dir=ltr] .legend-percentage{
    font-size: 16px;
    font-weight: 500;
    margin-left: 0px;
}
html[dir=rtl] .legend-percentage{
    font-size: 16px;
    font-weight: 500;
    margin-right: 0px;
}

/* requires important because recharts uses alot of inline styling */
.dbchartcard-pie .recharts-wrapper {
    height: 23.5rem !important ;
}

.dbchartcard-pie .recharts-surface {
    height: 23.5rem !important ;
}

.dashboard-container .dbchartcard-pie {
    text-align: center;
    border: none;
}

