.search-button{
    /* height: 58px; */
    /* background-color:#3d6ac7; */
}

.search-button:hover{
    /* background-color:#0e4dcc(75, 74, 74); */
}

/* .searchbar-container{
    width: 100%;
}

.dbsearchbar.form-group{
    box-sizing: content-box;
}

.dbsearchbar .form-control{
    position: relative;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    height: 60px;
}

.searchbar-container .form-inline{
    flex-flow: initial;
}

.search-navbar-item-icon {
    font-size: 18px;
} */
.dashboardSearch{
    height: 60px;
    display: flex;
    align-items: center;
}

.dashboardSearch .smart-content{
    width: 92%;
}

.searchbar-container .col{
    flex-direction: row;
    display: flex;
}

.searchbar-input{
    width: 100%;
    align-self: center;
    margin-bottom: 0;
}

.searchbar-input>div{
    justify-content: center;
    display: flex;
}

.searchbar-input>div>[name="title"]{
    flex-grow: 1;
}

@media (max-width: 992px) {
    .form-inline{
    }
    .dbsearchbar.form-group{
        width: 92%;
        box-sizing: content-box; 
    }
    
    /* .searchbar-container{
        width: 100%;
        display: block;
    } */
    .search-button{
        height: 48px;
        /* background-color:#3d6ac7; */
        
    }
    .dbsearchbar .form-control{
        position: relative;
        height: 40px;
        font-size: 10px;
        width: 254px
    }

    .search-navbar-item-icon {
        font-size: 14px;
    }

    /*Nav-item-icon align for Edge*/
    @supports (-ms-ime-align: auto) {
        .search-navbar-item-icon {
                font-size: 17px;
        }
    }

}

/*Nav-item-icon align for Edge*/
@supports (-ms-ime-align: auto) {
    .search-navbar-item-icon {
          font-size: 17px;
    }
  }

  @media only screen 
and (min-width: 414px) 
and (max-height: 736px) {

    .dbsearchbar .form-control{
        position: relative;
        height: 40px;
        font-size: 10px;
        width: 292px
    }
}