html[dir=rtl] .modal-dialog {
	max-width: 1000px;
	margin: 1.75rem auto;
	border-radius: 5px;
	box-shadow: 0 3px 8px rgba(0,0,0,0.5);
}
html[dir=rtl] .modal-content {
	border: none;
}
html[dir=rtl] .modal-title {
	font-size: 1.5rem;
	color: #005eb8;
}
html[dir=rtl] .modal-body, html[dir=rtl] .modal-header {
	padding-left: 50px;
	padding-right: 50px;
	border: none;
}
html[dir=rtl] .modal-header {
	padding: 40px 50px;
}
html[dir=rtl] .modal-dialog .result-block {
	border: none;
	background: none;
	padding: 0;
}
html[dir=rtl] .modal-footer {
	text-align: center;
	justify-content: center;
	border: none;
	padding-bottom: 40px;
}
html[dir=rtl] .modal-dialog .result-footer {
	position: static;
	display: inline-block;
	background: none;
	padding: 0;
	margin: 20px 0;
}
html[dir=rtl] #summary .result-footer {
	position: absolute;
	left: 0;
	bottom: 0px;
	width: 100%;
	background: #f7f7f7;
	border-radius: 0 0 6px 6px;
	padding: 8px 20px;
	margin: 0;
}
html[dir=rtl] #summary .result-block {
	border: 1px solid #ccc;
	background: #fff;
	padding: 20px;
	margin-bottom: 20px;
	border-radius: 5px;
	position: relative;
	text-align: right;
	padding-bottom: 68px;
	font-size: 14px;
}
html[dir=rtl] .modal-backdrop {
	background-color: rgba(219,219,219,0.80);
	opacity: 0.8!important;
}
html[dir=rtl] .card-header {
	padding: 0;
	text-align: right;
	background: none;
	border: none;
}
html[dir=rtl] .card-header .card-link {
	display: block;
	padding: 13px;
	border-radius: 5px;
	position: relative;
	padding-right: 50px;
}
html[dir=rtl] .card-header .card-link, html[dir=rtl] .card-header .card-link.collapsed {
	color: #666;
	background: #efefef;
	text-transform: uppercase;
}
html[dir=rtl] .modal-dialog .card-body {
	padding: 20px 0;
}
html[dir=rtl] .card-header .card-link::before {
	content: "\f139";
	font-family: "Font Awesome 5 Pro";
	font-size: 24px;
	position: absolute;
	left: 15px;
	top: 50%;
	line-height: 100%;
	margin-top: -12px;
}
html[dir=rtl] .card-header .card-link.collapsed::before {
	content: "\f13a";
	font-family: "Font Awesome 5 Pro";
}
html[dir=rtl] #accordion .card {
	border: none;
	margin-bottom: 10px;
}
html[dir=rtl] .card-body .jumbotron {
	background: none;
	text-align: right;
	padding: 10px 0;
	margin-bottom: 15px;
	border-bottom: 1px dashed #999;
	border-radius: 0;
	font-size: 14px;
}
html[dir=rtl] .card-body .jumbotron h5 {
	margin: 0;
	font-size: 17px;
}
html[dir=rtl] .card-body .jumbotron p {
	margin: 0;
}
html[dir=rtl] .modal-header .close {
	position: absolute;
	left: 50px;
	top: 44px;
	border: 1px solid #999;
	padding: 0;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	text-shadow: none;
	color: #999;
	opacity: 1 !important;
	font-size: 16px;
	z-index: 9;
}
html[dir=rtl] .modal-header {
	padding: 40px 50px 0 50px;
}
html[dir=rtl] .mobile-sub-nav {
	display: none;
}

html[dir=rtl] .radio-label {
    margin-right: 25px;
    margin-bottom: 0px;
}

html[dir=rtl] .button-group-center{
    display: flex;
    justify-content: center;
}

html[dir=rtl] .language-nav {
	margin: 9px;
}