.left-tree-container {
    padding-right: 0;
}

.right-tree-container {
    padding-left: 0;
}

.qnset-card-container {
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 10px !important;
}

.qnset-card-container .key-card-body {
    padding: 20px;
}

.qnset-card-container .qnset-key-row {
    padding-top: 4px;
    margin-top: 10px;
}

.qnset-card-container .caret-icon {
    position: absolute;
    top: -5px;
}

.qnset-card-container .actions .tree-icon {
    font-size: 1rem;
}

.actions .fa-sort {
    cursor: pointer;
}

.tree .node:hover {
    background: #EDEDED;
    border-radius: 5px;
}

.tree .node .actions {
    background: transparent;
}

.tree-card-header .btn-minimize {
    margin-right: 10px;
    background-color: #0772b8;
    border-color: white;
    font-size: 12px;
    display: flex;
    width: 30px;
    align-self: center;
    justify-content: center;
    padding-right: 13px;
}

.selected-node {
    background: #ADD8E6;
    border-radius: 5px;
}

.tree .node.selected-node:hover {
    background: #ADD8E6;
    border-radius: 5px;
}

.faq-body-content {
    margin: 30px 30px 0px 30px;
}

.preview-faq {
    max-width: 1000px;
}

.accordion-topic {
    padding: 0.375rem 0px;
}

.preview-faq-name{
    margin:0px;
    font-size: 2rem;
}

.preview-faq-topic{
    font-size: 1.5rem;
}

.preview-faq-subtopic{
    font-size: 1.25rem;
}

.preview-faq-question{
    font-size: 1.25rem;
    color: #0772b8;
}

.preview-faq-answer{
    font-size: 1rem;
    padding-bottom: 10px;
}

.accordion-sub-topic{
    cursor: pointer;
}

.create-draft-btn{}