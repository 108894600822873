.griddle-table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border: 1px solid #dee2e6;
}

.griddle-table thead td,
.griddle-table thead th {
    font-weight: 600;
    border-bottom-width: 2px;
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.griddle-table td {
    /* border: 1px solid #dee2e6; */
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.griddle-row td.griddle-cell {
    word-break: break-all;
}

.griddle-table th {
    border: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

[dir=ltr] .griddle-table th {
    padding-left: 0.5em;
}

[dir=rtl] .griddle-table th {
    padding-right: 0.5em;
}

.griddle-table-body {
    background-color: #fff;
}

.griddle-table-heading {
    /* background-color: #0772b8; */
    color: #fefefe;
    /* color: #6C757D; */
    font-size: 0.85rem;
}

.griddle-actions {
    text-align: center;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.checkbox1 {
    padding-top: 10px;
}

.checkall {
    margin-bottom: 15px;
    margin-top: 10px;
    margin-left: 55px;
    padding-right: 10px;
}

.buttongroup {
    padding-left: 17px;
    padding-bottom: 15px;
}

.move-btn {
    background-color: white !important;
    color: rgb(117, 117, 117);
    border-color: #ccc;
}

.moveto-btn {
    background-color: white !important;
    color: rgb(117, 117, 117);
    border-color: #ccc;
}

.title-click {
    color: red;
}


/*Select Filter*/

.filter-container {
    width: 30%;
    /* float: right; */
    margin-bottom: 10px;
}

.select-filter {
    width: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
    float: left;
    text-align: start;
}

.griddle-filter {
    display: block;
    width: 100%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    float: right;
    margin-bottom: 10px;
    margin-top: 10px;
}

@media (max-width: 992px) {
    .select-filter {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: start;
    }
    .griddle-filter {
        display: block;
        width: 100%;
        flex: 0 0 16.666667%;
        max-width: 100% !important;
        height: calc(2.25rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
        -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
        float: right;
        margin-bottom: 10px;
        
    }
    .custom-griddle-filter {
        display: block;
        width: 100%;
        flex: 0 0 16.666667%;
        max-width: 100% !important;
        height: calc(2.25rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
        -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
        float: right;
        margin-bottom: 10px;
    }
}

.griddle-page-select {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    border: 1px solid #ced4da;
    margin: 0px 5px;
}

.griddle-pagination {
    text-align: end;
}

.griddle-previous-button,
.griddle-next-button {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #6c757d !important;
    /* border-color: #005F9F !important; */
}

.griddle-previous-button:hover,
.griddle-next-button:hover {
    cursor: pointer;
}

.action-button-group {
    display: flex;
    align-items: center;
    justify-content: center;
}

html[dir=ltr] .action-button-group .action-btn {
    margin: 0 5px 0 0;
    border: 1px solid;
    color: #ffffff !important;
    font-size: 12px;
    padding: 2px 5px;
}
html[dir=rtl] .action-button-group .action-btn {
    margin: 0 0 0 5px;
    border: 1px solid;
    color: #ffffff !important;
    font-size: 12px;
    padding: 2px 5px;
}

.mobile-action-btn label {
    cursor: pointer;
}


/* .action-button-group .action-btn:last-child {
    margin: 0px;
} */

.griddle-noResults {
    text-align: center;
}


/************* Card **************/

.card-container {
    margin-bottom: 20px;
}

.card-width {
    width: 300px;
}

.card-header {
    color: white;
    padding-top: 0.4em !important;
    padding-bottom: 0.4em !important;
    margin-top: 0 !important;
}

.badge-success {
    background-color: #57984f !important;
}

.card-footer {
    padding-top: 0.2em !important;
    padding-bottom: 0.2em !important;
}

.card-icon-grp {
    text-align: center;
    padding: 3px;
    overflow: hidden;
}

.card-icon-grp-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
}

.card-button {
    width: 30px;
    height: 30px;
    padding-right: 15px;
}

.card-button-icon {
    padding-right: 10px;
    font-size: 25px;
}

.horizontal-line {
    border-bottom: 1px solid #e8e8e8;
    margin-left: 0px;
    margin-right: 0px;
}

.label-align {
    vertical-align: middle;
    margin-bottom: 4px;
}

@media (max-width: 344px) {
    .label-align {
        vertical-align: middle;
        padding-bottom: 0px;
    }
    .card-icon-grp {
        text-align: center;
        overflow: hidden;
    }
    .card-icon-grp-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
    }
    .card-button-icon {
        padding-right: 8px;
        font-size: 1.4rem;
    }
}

@media (max-width: 320px) {
    .label-align {
        display: none;
    }
    .card-button-icon {
        font-size: 1.6rem;
    }
    .btn-invisible {
        padding: 2px 0px !important;
    }
}

@media (max-width: 992px) {
    .select-mobile {
        text-transform: none;
        margin: 0px 10px !important;
        min-height: 30px !important;
        min-width: 50px !important;
    }
    .div-flex {
        display: flex;
        justify-content: center;
    }
}

.card-table {
    width: 100%;
    margin-bottom: 0rem;
    background-color: transparent;
}

.card-table-body {
    flex: 1 1 auto;
    padding: 1rem;
    overflow: hidden;
}

.card-table-td {
    border-top: 0px !important;
    padding: 2px 0px !important;
    vertical-align: middle;
}

.card-table-title {
    border-top: 0px !important;
    padding: 2px 0px;
}

.card-table-data {
    border-top: 0px !important;
    padding: 2px 0px;
}

.pagination-group {
    text-align: end;
}

.nowrap {
    white-space: nowrap;
}

.btn-invisible {
    border-radius: 4px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.65);
    ;
    margin: 2px;
    font-size: 0.85rem;
    padding: 7px 0px;
}

.card-container .card-icon-grp .btn .svg-inline--fa {
    height: 0.73em;
}


/* @media (max-width: 992px) {

.griddle-table {
    table-layout: fixed;
    background: transparent;
} */


/* responsive 
.griddle-table thead {
    display: none;
}

.griddle-table tbody tr {
    border-bottom: 1px solid #dee2e6;
    cursor: pointer;
    display: block;
  }

.griddle-table tbody td {
    border: 0;
    display: block;
    padding: 5px 0px 5px 50%;
    position: relative;
  }

  .griddle-table tbody td[style] {
    width:auto !important;
 }

  .griddle-table tbody td:first-of-type::after {
    visibility: hidden;
  }
  .griddle-table tbody td:after {
    content: '';
    width: calc(100% - 40px);
    display: block;
    margin: 0 auto;
    height: 0px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .griddle-table tbody td:before {
    content: attr(data-title);
    display: table-cell;
    height: 100%;
    left: 0;
    top: 0;
    margin: auto;
    padding: 5px 0 0 15px;
    position: absolute;
    vertical-align: middle;
    white-space: nowrap;
    width: 45%;
    font-weight: bold;
    background-color: #005F9F;
    color: #fff;
  }

  .griddle-table tbody > tr.griddle-noResults td {
    border: 0;
    display: block;
    padding: 5px 0px 5px 0;
    position: relative;
  }

  .griddle-table tbody > tr.griddle-noResults td::before{
    background-color: transparent;
  }


}
*/


/*Brought over from emotion css*/

.smart-griddle-table .griddle-table-heading,
.griddle-table-heading-cell {
    background-color: #0772b8;
}

.no-pagination .griddle-pagination {
    display: none;
}

.no-pagination .pagination-group {
    display: none;
}

.mobilecard-header {
    background-color: #0772b8;
    padding-top: 0.5em !important;
}

.draft-mobilecard-header {
    background-color: #585858;
    padding-top: 0.5em !important;
}

/*DIRECTION Changes */

[dir=rtl] td, [dir=rtl] tr {
    text-align: start;
}

[dir=rtl] .griddle-filter {
    float: left;
}

[dir=rtl] .select-filter {
    float: right;
}

