/** Inbox CSS here **/

.card-container{
    margin-bottom: 20px;
}

.card-width{
    width: 300px; 
}

.card-header{
    background-color: #3d6ac7;
    color: white;
    padding-top: 0.4em !important;
    padding-bottom: 0.4em !important;
    margin-top: 0 !important;
}

.badge-success{
    background-color: #57984f;
}

.card-footer{
    padding-top: 0.2em !important;
    padding-bottom: 0.2em !important;
}

.card-icon-grp{
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
}

.card-button{
    width: 30px;
    height: 30px;
    padding-right: 15px;
}

.card-button-icon{
    padding-right: 10px;
    font-size: 25px;
}

.horizontal-line{
    border-bottom: 1px solid #e8e8e8;
    margin-left: 10px; 
    margin-right: 10px;
}

.divstyle{
    margin-left: 10px;
    margin-top: 11px;
    border: 0;

}

.title-click{
    color: red;
}

.eye-icon{
    padding-right: 16px;
    color: #888;
    font-size: 15px;
}

.trash-icon{
    padding-right: 16px;
    color: #888;
    font-size: 15px;
}
/* .trash-icon:hover{
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
} */

.checkbox-input{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}