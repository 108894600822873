/* Chart Card*/
.cc-card{
    padding-top: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 1rem;
    flex-grow: 1;
    display: flex;
}

.cc-card .cc-description{
    font-size: 13px;
    font-weight: 400;
}

.cc-card .media-heading{
    margin-bottom: 0;
}

.cc-card .cc-card{
    margin-left: auto;
    margin-right: auto;
}

.cc-card .rv-radial-chart{
    margin-left: auto;
    margin-right: auto;
}

/* Number Card*/
.nc-card {
    flex-grow: 1;
    display: flex;
    margin-bottom: 15px;
}

.nc-card .nc-description{
    font-size: 13px;
    font-weight: 400;
}

.nc-card .nc-number{
    font-size: 40px;
    line-height: 1;
    text-align: right;
    font-weight: 600;
    justify-content: flex-end;
    align-content: flex-end;
    display: flex;
    color: #3d6ac7;
}

.nc-card .media-heading{
    margin-bottom: 0;
}

html[dir=ltr] .nc-card .card-body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
}
html[dir=rtl] .nc-card .card-body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
}


/* Text Card*/
.tc-card{
    flex-grow: 1;
    display: flex;
    margin-bottom: 15px;
}
.tc-card .tc-header{
    border-radius: .25rem .25rem 0 0;
    background-color: #3d6ac7;
    color: #FFFFFF;
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: .25rem;
}
.tc-card .tc-text{
    display: flex;
    padding-top: .50rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 1.25rem;
}
.tc-card .media-heading{
    margin-bottom: 0;
}
.tc-card .card-body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

/* TimeLine Card*/
.tlc-card{
    flex-grow: 1;
    display: flex;
}
.tlc-card .tlc-description{
    font-size: 13px;
    font-weight: 400;
}
.tlc-card .ant-timeline{
    font-family: 'Open Sans', sans-serif;
}
.tlc-card .ant-timeline-item-last, .timeline-card>.card-body{
    padding-bottom: 0;
}
.tlc-card .ant-timeline-item-head{
    padding: 0;
    /* border-color: #3d6ac7; cannot use as it will clash with the library colors */
}
.tlc-card .ant-timeline-item-head-custom{
    top: 5px;
    /* font size and height should be the same to position the icon in the correct alignment */
}
.tlc-card .ant-timeline-item-last .ant-timeline-item-content {
    min-height: unset;
}

/* Pie Chart */
.pie-no-data {
    padding-top: 100px;
    padding-bottom: 100px;
}

.active-pie-chart-legend
{
    font-size: 13.5px;
}
