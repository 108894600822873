[dir=rtl] *{ outline: none!important;}

[dir=rtl] .graph-content ul.main-tab-graph {
    border: none;
    margin-top: 30px;
	margin-bottom: 10px;
}

[dir=rtl] .graph-content .dashboard-serach {
    background: #3498db;
    width: 100%;
    border-radius: 5px 5px 0 0;
	padding: 10px 15px;
}

[dir=rtl] .graph-content .search-box.select-search-box {
    display: table;
    width: 100%;
}

[dir=rtl] .graph-content .search-box.select-search-box .search-cell:first-child {
    width: auto;
    margin-left: 10px;
}

[dir=rtl] .graph-content .search-box.select-search-box .search-cell:first-child .btn-secondary {
    height: 40px;
    background: #fff;
    border: none;
    color: #333;
    font-size: 14px;
}

[dir=rtl] .graph-content .search-box.select-search-box .search-cell:first-child .btn-secondary i {
    margin-left: 10px;
}
[dir=rtl] .graph-content .search-cell {
    width: 60%;
    display: inline-block;
    float: right;
}

[dir=rtl] .graph-content .tab-nav-tabs{ margin-bottom: 20px;}

[dir=rtl] .graph-content .tab-nav-tabs .tab-nav-item {
    margin: 0;
}

[dir=rtl] .graph-content .tab-nav-tabs .tab-nav-item .tab-nav-link {
    border: 1px solid #3498db!important;
    box-shadow: none;
    border-radius: 40px;
    padding: 2px;
    color: #3498db!important;
    margin-left: 10px;
    text-transform: uppercase;
    font-weight: 600!important;
    padding-right: 2px;
    font-size: 18px!important;
    position: relative;
    padding-left: 30px;
}


[dir=rtl] .graph-content .tab-nav-tabs .tab-nav-item .tab-nav-link.active, [dir=rtl] .graph-content .tab-nav-tabs .tab-nav-item .tab-nav-link:hover{
    background: #3498db;
    border-color: #3498db!important;
    color: #fff!important;
}

[dir=rtl] .graph-content .tab-nav-tabs .tab-nav-item .tab-nav-link span {
    display: inline-block;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    right: 2px;
    background: #3498db;
    top: 0;
    margin-left: 10px;
    border-radius: 50%;
    color: #fff;
    line-height: 50px;
    text-align: center;
	font-size: 30px;
}
[dir=rtl] .graph-content .tab-nav-tabs .tab-nav-item .tab-nav-link.active span, [dir=rtl] .graph-content .tab-nav-tabs .tab-nav-item .tab-nav-link:hover span{background: #fff; color: #3498db;}


[dir=rtl] .graph-content .tab-content {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
}



[dir=rtl] .graph-content .select-list {
    border-radius: 0 5px 5px 0;
    background: #fff;
    height: 40px;
}

[dir=rtl] .graph-content .select-list .css-yk16xz-control,[dir=rtl] .graph-content .select-list .css-1pahdxg-control {
    border: none;
    height: 40px;
	min-width: 600px;
}

[dir=rtl] .graph-content .input-group .row.form-group {
    margin: 0;
}

[dir=rtl] .graph-content .input-group .row.form-group .col-form-label {
    display: none;
}

[dir=rtl] .graph-content .input-group .row.form-group .col-lg-12{ padding:0;}
[dir=rtl] .graph-content .input-group .input-group-append button.btn.btn-spacer.btn.btn-secondary {
    width: 40px;
    background: #fff;
    border: none;
    color: #999;
    font-size: 20px;
	height: 40px;
	margin-right: -2px;
	outline: none!important;
	box-shadow: none!important
}


@media only screen and (max-width:1199.98px) {
    [dir=rtl] .graph-content .select-list .css-yk16xz-control, [dir=rtl] .graph-content .select-list .css-1pahdxg-control {
    min-width: 460px;
}
}
@media only screen and (max-width:991.98px) {
    [dir=rtl] .graph-content .tab-nav-tabs .tab-nav-item {
	width: auto;
    text-align: center;
}
[dir=rtl] .graph-content .select-list .css-yk16xz-control, .graph-content .select-list .css-1pahdxg-control {
    min-width: 440px;
}
	
}

@media only screen and (max-width:767.98px) {
    [dir=rtl] .graph-content .select-list .css-yk16xz-control, .graph-content .select-list .css-1pahdxg-control {
    min-width: 330px;
}
	
}

@media only screen and (max-width:575.98px) {
    [dir=rtl] .graph-content .search-cell {
    width: 100%;
    display: inline-block;
}
[dir=rtl] .graph-content .tab-nav-tabs .tab-nav-item {
    margin: 0;
    margin-bottom: 10px;
    width: 100%;
    text-align: right;
}
	
[dir=rtl] .graph-content .search-box.select-search-box .search-cell:first-child {
    width: 100%;
    margin-left: 10px;
    text-align: center;
    margin: 0 0 10px 0;
}
	
[dir=rtl] .graph-content .select-list .css-yk16xz-control, [dir=rtl] .graph-content .select-list .css-1pahdxg-control {
    min-width: 280px;
    margin: auto;
}
	
[dir=rtl] .graph-content .search-cell .input-group {
    width: 320px;
    margin: auto;
}
	
[dir=rtl] .graph-content .tab-nav-tabs .tab-nav-item .tab-nav-link{font-size: 16px!important;}
	
}

[dir=rtl] div.vis-tooltip{ border-radius: 5px!important; padding: 10px; background: #0d65be!important; color: #fff; min-width: 300px; border: none; text-align: right;}

[dir=rtl] .vis-tooltip label {
    width: 100px;
}