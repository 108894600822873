.WHC-Table .label-default {
    display: none;
}

.WHC-Table .rw-widget {
    width: 150%
}

.WHC-Table .form-group {
    margin-bottom: 0px;
    margin-top: 4px;
}

.WHC-Table thead {
    background-color: #4183D7;
    color: white;
}

.WHC-Table thead th {
    border: 1px solid white
}

.WHC-Table .clearField .fa-times {
    right: 0px;
}

.WHC-Table td {
    font-weight: bold;
    vertical-align: middle;
}

.WHC-Table .label-error {
    display: none;
}

.WHC-Table .label-feedback {
    width: 150%;
    margin-left: 0px;
}

@media (max-width: 1350px) {
    .WHC-Table tr {
        display: inline-grid;
        margin-bottom: 15px;
    }
    .WHC-Table thead {
        display: none;
    }
    .WHC-Table .rw-widget-picker.rw-widget-container {
        width: auto;
    }
    .WHC-Table .rw-widget {
        width: max-content;
    }
    .WHC-Table {
        text-align: center;
    }
}