/*************** Styling: Header ***************/


html[dir=ltr] .header-nav {
    background-color: #FFFFFF;
    display: flex;
    top: 0;
    height: 60px;
    z-index: 4;
    border-bottom: 1px solid #dee2e6;
}
html[dir=rtl] .header-nav {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row-reverse;
    top: 0;
    height: 60px;
    z-index: 4;
    border-bottom: 1px solid #dee2e6;
}

.header-logo {
    width: 200px;
    object-fit: contain;
}

html[dir=ltr] .header-menu-btn {
    margin: 0px 25px 5px 15px;
}
/* html[dir=rtl] .header-menu-btn {
    margin: 0px 15px 5px 25px;
} */

#sidebarBtn div {
    padding: 2px 10px;
}

.navbar-header-icon {
    padding-top: 5px;
    font-size: 1.6em;
}
  
html[dir=ltr] .navbar-header-text {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    font-size: .9em;
}
/* html[dir=rtl] .navbar-header-text {
    padding-top: 10px;
    display: flex;
    justify-content: flex-start;
    font-size: .9em;
} */
  
html[dir=ltr] .navbar-header-text-icon {
    margin-left: 45px;
    margin-right: 5px;
    margin-top: 5px;
    font-size: .9em;
}
/* html[dir=rtl] .navbar-header-text-icon {
    margin-left: 5px;
    margin-right: 45px;
    margin-top: 5px;
    font-size: .9em;
} */
  
.navbar-header-dropdown {
    font-size: .85rem;
}


@media only screen and (max-width: 992px) {
    #sidebarBtn{
        display: flex;
    }
    .header-logo-col {
        display: flex;
        margin: auto;
    }
    .header-logo {
        width: 150px;
        margin: 5px 0;
        height: 40px;
    }
    .header-nav {
        justify-content: space-between;
        position: fixed;
        top: 0;
        z-index: 4;
        width: 100vw;
        height: 50px;
    }
}

@media only screen and (min-width: 993px) {
    #sidebarBtn{
        display: none;
    }
    .header-logo {
        display: none;
    }
    .header-logo-col {
        display: none;
    }
}

@media only screen and (max-width: 992px) {
    html[dir=ltr] .header-nav{
        justify-content: space-between;
        position: fixed;
        top: 0;
        right: 0;/**/
        margin-left: 0px;
        left: 0px;
        margin-right: 0px;
        z-index: 4;
        width: 100vw;
        height: 52px;    
        background-color: #FFFFFF;
        border-bottom: 1px solid #dee2e6;
        display: flex;
    }
    html[dir=rtl] .header-nav{
        justify-content: space-between;
        position: fixed;
        top: 0;
        right: 0;/**/
        margin-left: 0px;
        left: 0px;
        margin-right: 0px;
        z-index: 4;
        width: 100vw;
        height: 52px;    
        background-color: #FFFFFF;
        border-bottom: 1px solid #dee2e6;
        display: flex;
        flex-direction: row-reverse;
    }
    .header-nav #sidebarBtn {
        display: flex;
    }
    .header-nav a.btn-neutral{
        padding: 2px 10px;
        cursor: pointer;
        text-decoration: none;    
        border-radius: 4px;
        background-color: #3d6ac7;
        color: rgb(255,255,255) !important;
        margin: 2px;
    }
    .header-nav a.btn-neutral:hover{
        background-color: #011D75;
    }
}