.onlinehelp-container{
    margin-top: 10px;
}

.card-deck .tree-card {
    min-height: 600px;
    margin: 0;
    margin-bottom: 70px;
}

.tree-card-header {
    height: 40px;
    background-color: #0772b8;
    border-bottom: none;
    font-size: 15px;
    display: flex;
    white-space: nowrap;
}

.tree-card .form-group-inline{
    padding : 0;
}

.tree-card .label-default{
    text-align: left;
}

.tree-card-header p {
    float: left;
    margin-bottom: 0px;
    padding-top: 3px;
}

.tree-card-body {
    padding-top: 2px;
    margin-top: 0px;
}

.tree-card-header .add-button {
    float: right;
    margin-left: 4px;
    background-color: #0772b8;
    border-color: white;
    display: inline-flex
}

.tree-card-header .add-button:hover {
    background: #06588f;
}

.actions .fa-add {
    color: #00a659;
    cursor: pointer;
    display: inline-block;
    margin-right: 3px;
    font-size: 17px;
}

.actions .fa-add:hover {
    color: #197528;
}

.actions .fa-edit {
    color: #0772b8;
    cursor: pointer;
    display: inline-block;
    margin-right: 2px;
    font-size: 17px;
}

.actions .fa-edit:hover {
    color: #06588f;
}

.actions .fa-del {
    color: #c41c23;
    cursor: pointer;
    display: inline-block;
    font-size: 17px;
}

.actions .fa-del:hover {
    color: #8a1717;
}

.actions .fa-sort {
    color: #5f5e5e;
    font-size: 19px;
    margin-right: -5px;
}

.actions .fa-sort:hover {
    color: #353535;
}

.fa-open {
    font-size: 16px;
}

.fa-close {
    font-size: 16px;
}

.node .actions {
    position: absolute;
    display: none;
    float: right;
    background-color: white;
    right: 0;
    top: -2.5px;
}

.tree ul {
    list-style: none;
    padding-left: 9px;
}

.tree ul li {
    position: relative;
    padding-left: 14px;
    padding-top: 5px;
}

.tree ul li:before {
    content: '';
    height: 1.4px;
    width: 10px;
    background-color: #5c87db;
    position: absolute;
    top: 16px;
    left: 0;
    margin: auto;
}

.tree ul li:after {
    content: '';
    width: 1.4px;
    height: 100%;
    background-color: #5c87db;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

.tree ul li:last-child {
    margin-bottom: auto;
}

.tree ul li:last-child:after {
    height: 16px;
}

.tree .node {
    display: block;
    cursor: pointer;
    color: #0772b8;
    font-size: 14px;
    /*position: relative;*/
    z-index: 1;
    /* min-width: 170px; */
}

.tree .node .node-name{
    white-space: normal;
}

.tree .node:hover {
    color: #033fb8;
}

.tree ul li .node:hover .actions {
    display: block;
}

.oh-card-footer {
    background-color: white;
    display: flex;
    justify-content: center;
}

.oh-card-footer .btn {
    max-height: 30px;
    margin: 2px;
}

.tree-tb {
    float: right;
    margin-bottom: 2px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    color: #6d6d6d;
    height: -webkit-fill-available;
    width: auto;
    margin-left: auto;
    
}

.tree-fa-circle {
    font-size: 8px;
    margin-right: 6px;
    margin-left: 4px;
}