html[dir=ltr] ul.nav {
	padding-left: 0;
}
html[dir=rtl] .nav {
	padding-right: 0;
}
html[dir=ltr] .navbar-header-dropdown {
	text-align: left;
}
html[dir=rtl] .navbar-header-dropdown {
	text-align: right;
}

html[dir=rtl] .plaintext {
	text-align: right
}

/* Fixes weird date/time input layout for RTl */
html[dir=rtl] .rw-widget-input {
    direction: initial;
    text-align: right;
}

/* Fixes weird date/time input layout for LTR */
html[dir=ltr] .rw-widget-input {
    direction: initial;
    text-align: left;
}

/* Fix alignment of nabbar item*/
html[dir=ltr] .layout-1d .nav .navbar-item {
	text-align: left;
}
html[dir=rtl] .layout-1d .nav .navbar-item {
	text-align: right;
}
/* Fixes alignment of copyright footer at the bottom of navbar */
html[dir=ltr] .footer-copyright {
	text-align: left;
}
html[dir=rtl] .footer-copyright {
	text-align: right;
}

[dir=rtl] .fa-list, [dir=rtl] .fa-caret-left, [dir=rtl] .fa-caret-right {
    transform: scaleX(-1);
}

/* Fixes rtl spacing issues for radio buttons */
html[dir=ltr] .form-group .form-check-inline {
	padding-left: 0 !important;
	margin-right: .75rem !important;
	margin-left: auto !important;
}
html[dir=rtl] .form-group .form-check-inline {
	padding-right: 0 !important;
	margin-left: .75rem !important;
	margin-right: auto !important;
}
html[dir=ltr] .accordion-content .form-group .col, [dir=ltr] .accordion-content .form-group .col-lg-7 {
	text-align: left;
}
html[dir=rtl] .accordion-content .form-group .col, [dir=rtl] .accordion-content .form-group .col-lg-7 {
	text-align: right;
}
/* Fixes rtl alignment for enquiry pages */
html[dir=ltr] .grid-container .container .col-gutter .chevron {
	float: right
}
html[dir=rtl] .grid-container .container .col-gutter .chevron {
	float: left
}
html[dir=ltr] .grid-container .container .col-gutter.record-count {
	text-align: left;
}
html[dir=rtl] .grid-container .container .col-gutter.record-count {
	text-align: right;
} 
.wizard-steps-container.col {
	text-align: center;
}
/* Fix issue where arrow in datepicker widget point inwards in RTL */
.rw-calendar-header {
	direction: initial;
}

html[dir=ltr] .form-content > .panel-heading {
	text-align: left;
}
html[dir=rtl] .form-content > .panel-heading {
	text-align: right;
}

[dir=ltr] .add-action-button {
	float: right;
}

[dir=rtl] .add-action-button {
	float: left;
}

/* Fixes issues relating to card in language list */
html[dir=ltr] .key-card-action-button.action-button-group {
	float: right;
}
html[dir=rtl] .key-card-action-button.action-button-group {
	float: left;
}
html[dir=ltr] .text-right {
	text-align: right !important;
}
html[dir=rtl] .text-right {
	text-align: left !important;
}
html[dir=ltr] .key-row .col-6 {
	text-align: left;
}
html[dir=rtl] .key-row .col-6 {
	text-align: right;
}
html[dir=ltr] .key-card-body .key-card-action-button {
	float: right;
}
html[dir=rtl] .key-card-body .key-card-action-button {
	float: left;
}
html[dir=ltr] .language-header-btn {
	text-align: right !important;
}
html[dir=rtl] .language-header-btn {
	text-align: left !important;
}